import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
// // import { CardHeader } from "@mui/material";
import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { MdSubscriptions } from "react-icons/md";
import '../banner.css';
import '../brokers.css';
import '../buyback.css';
import '../GridStyles.css';
import ConfirmDialog from './ConfirmDialog';
import IPOStatusComponent from "./IPOStatusComponent";
import FaqComponent from './FaqComponent'; 

function RightsIssues({allRightsIssues}) {


  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log(allRightsIssues);

  const faqData = [
    {
      question: "What is a rights issue?",
      answer: "A rights issue is an offering of additional shares to existing shareholders, allowing them to purchase new shares at a discounted price, usually in proportion to their current holdings."
    },
    {
      question: "Why do companies conduct rights issues?",
      answer: "Companies conduct rights issues to raise capital for various purposes, such as funding new projects, reducing debt, or improving working capital, without taking on additional debt."
    },
    {
      question: "How do shareholders benefit from a rights issue?",
      answer: "Shareholders benefit by getting the opportunity to buy new shares at a discounted price. If the company's future growth prospects are strong, they may profit from an increase in share value."
    },
    {
      question: "Are there any risks associated with rights issues?",
      answer: "Yes, rights issues can dilute the value of existing shares if shareholders don't participate. Additionally, if the company’s financial health doesn’t improve, the stock price may fall further."
    },
    {
      question: "What is the process for participating in a rights issue?",
      answer: "Shareholders receive rights based on their existing shareholdings and can use those rights to buy additional shares at a discounted price. The process typically involves instructing your broker or through the online platform of your Demat account."
    },
    {
      question: "Can I sell my rights during a rights issue?",
      answer: "Yes, shareholders can choose to sell their rights in the market if they don’t wish to buy additional shares. These are often referred to as 'renounceable rights'."
    },
    {
      question: "Do brokers charge fees for rights issue transactions?",
      answer: "Brokers may charge a small processing fee or commission for handling rights issue transactions. The fee structure varies depending on the broker."
    },
    {
      question: "What happens if I don’t participate in a rights issue?",
      answer: "If you choose not to participate, your ownership in the company will be diluted, meaning your percentage of ownership in the company decreases, though your total number of shares remains unchanged."
    },
    {
      question: "What information do I need to provide to participate in a rights issue?",
      answer: "You need to provide your Demat account details, PAN, and specify the number of shares you wish to subscribe to. Your broker can assist with this process."
    },
    {
      question: "How do brokers assist with rights issues?",
      answer: "Brokers inform clients about rights issue offers, facilitate the subscription process, and help shareholders with renouncing or selling their rights if needed."
    },
    {
      question: "What are the tax implications of participating in a rights issue?",
      answer: "The tax implications depend on how long you hold the shares and the prevailing capital gains tax laws in your region. Typically, buying shares through a rights issue may have similar tax treatment to normal stock purchases."
    },
    {
      question: "Can I participate in a rights issue if I don’t own shares in the company?",
      answer: "No, only existing shareholders as of the record date are eligible to participate in a rights issue. However, you may be able to buy rights from existing shareholders if the rights are renounceable."
    },
    {
      question: "How do brokers provide updates on the rights issue process?",
      answer: "Brokers keep clients informed about the rights issue timeline, subscription status, and final allotment details. They also offer insights into the impact of the rights issue on the company’s stock."
    },
    {
      question: "What compliance requirements must brokers follow during a rights issue?",
      answer: "Brokers must comply with regulatory norms, such as SEBI guidelines and KYC requirements, ensuring proper execution of orders and transparency throughout the rights issue process."
    },
    {
      question: "What are the advantages of participating in a rights issue?",
      answer: "Participating in a rights issue allows shareholders to buy shares at a discounted price, potentially increase their shareholding, and benefit from future growth if the company's strategy succeeds."
    }
  ];
  
  
 console.log(allRightsIssues);
  // const [iconPills, setIconPills] = React.useState("1");
  // const [pills, setPills] = React.useState("1");
  const [ipoType, setIpoType] = useState("All IPO");

  // const handleClick = (value) => {
  //   window.open(value, '_blank');
  // };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const handleClick = (value) => {
      setExternalUrl(value);
      setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };

  const handleIpoTypeChange = (type) => {
    setIpoType(type);
    setActiveTab(type);
    setShowAllRows(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ff7847",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  }


  // // Filter rows based on IPO type
  // const filteredRows =
  //   ipoType === "All IPO"
  //     ? currentIPOs
  //     : currentIPOs.filter((row) => {
  //         if (ipoType === "Mainboard IPO") {
  //           const lastValue = row[8] && row[8].split(" ").pop();
  //           return lastValue === "NSE";
  //         } else if (ipoType === "SME IPO") {
  //           const lastValue = row[8] && row[8].split(" ").pop();
  //           return lastValue === "SME";
  //         }
  //       });

  const [showAllRows, setShowAllRows] = useState(false);
  const [activeTab, setActiveTab] = useState("All IPO");
  const initialRowCount = 6;

  // const visibleRows = showAllRows ? filteredRows.length : initialRowCount;

  const openExternal = (url) => {
    window.open(url, '_blank');
  };
  
  const handleDivClick = (url) => {
    // Redirect to another page when clicking outside the buttons
    window.location.href = url;
  };

  const handleButtonClick = (event, url) => {
    event.stopPropagation(); // Prevent the div's onClick from triggering
    if (url) {
      openExternal(url);
    }
  };

  function shuffleArray(array) {
    return array.slice().sort(() => Math.random() - 0.5);
  }
  // Filter the brokers where Broker_Banner is an empty string
  const filteredBrokers = allRightsIssues && allRightsIssues?.filter(broker => broker.Broker_Banner !== "");
  const shuffledBrokers = filteredBrokers && shuffleArray(filteredBrokers)?.slice(0, 4);




  const [selectedFilter, setSelectedFilter] = useState('Current');

  const [visibleCount, setVisibleCount] = useState(10);


  // // Filtered Buybacks based on selected button
  // const filteredBuybacks = allBuybacks.filter((buyback) => {
  //   if (selectedFilter === 'All') return true;
  //   if (selectedFilter === 'Current') return buyback.buyback_type === 'Current Buyback';
  //   if (selectedFilter === 'Upcoming') return buyback.buyback_type === 'Upcoming Buyback';
  //   if (selectedFilter === 'Closed') return buyback.buyback_type === 'Closed Buyback';
  //   return true;
  // });


// Get the current date
// Helper function to remove the time component from a date
const stripTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

// Get the current date without time
const currentDate = stripTime(new Date());

// Filtered Buybacks based on selected button
const filteredRightsIssues = allRightsIssues && allRightsIssues
  ?.filter((issue) => {
    // Convert buyback.close_date to a Date object and remove time for comparison
    const closeDate = stripTime(new Date(issue.issue_close));
    // setVisibleCount(10);
    if (selectedFilter === 'All') return true;

    // For Current filter: return buybacks where currentDate <= closeDate
    if (selectedFilter === 'Current') return (currentDate >= stripTime(new Date(issue.issue_open)) && currentDate <= closeDate);

    // For Closed filter: return buybacks where currentDate > closeDate
    if (selectedFilter === 'Closed') return (currentDate > closeDate && issue.issue_open !== null);

    // For Upcoming filter: return buyback type matches 'Upcoming Buyback'
    if (selectedFilter === 'Upcoming') return (currentDate < stripTime(new Date(issue.issue_open)) || issue.issue_open === null);

    return true;
  })
  .sort((a, b) => {
    // Check if issue_open is null or undefined
    if (!a.issue_open) return -1; // a comes first if issue_open is null
    if (!b.issue_open) return 1;  // b comes first if issue_open is null
  
    // Otherwise, sort by issue_open in descending order
    return new Date(b.issue_open) - new Date(a.issue_open);
  });
  
  // // Sort by record_date in descending order for all cases
  // .sort((a, b) => new Date(b.issue_open) - new Date(a.issue_open));





  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + 10);
  };

  const handleShowLess = () => {
    setVisibleCount(10); // Reset to the initial 10 items
  };


  // const [selectedFilter, setSelectedFilter] = useState('All');


  return (
    <>
    {/* <Helmet>
  <title>Top Share Buybacks in India 2024 | Key Opportunities for Investors</title>
  <meta
    name="description"
    content="Discover the leading share buybacks in India for 2024. Learn about buyback opportunities, company strategies, and how to benefit from share repurchase programs."
  />
  <meta
    name="keywords"
    content="Share Buybacks, Top Buybacks 2024, Buyback Opportunities, Investment Strategies, Stock Repurchase, Buyback Experts, IPOtank, IPO tank"
  />

  <meta property="og:url" content="https://www.ipotank.com/buybacks" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Top Share Buybacks in India 2024 | Key Opportunities for Investors" />
  <meta property="og:description" content="Discover the leading share buybacks in India for 2024. Learn about buyback opportunities, company strategies, and how to benefit from share repurchase programs." />
</Helmet> */}

<Helmet>
  <title>Top Rights Issues in India 2024 | Key Opportunities for Investors</title>
  <meta
    name="description"
    content="Explore the leading rights issues in India for 2024. Understand the company strategies behind these issues, and learn how investors can capitalize on these opportunities."
  />
  <meta
    name="keywords"
    content="Rights Issues, Top Rights Issues 2024, Investment Opportunities, Stock Market, IPOtank, IPO tank, Shareholder Strategies, Capital Raising"
  />

  <meta property="og:url" content="https://www.ipotank.com/rights-issues" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Top Rights Issues in India 2024 | Key Opportunities for Investors" />
  <meta property="og:description" content="Explore the leading rights issues in India for 2024. Understand the company strategies behind these issues, and learn how investors can capitalize on these opportunities." />
</Helmet>



      <div style={{backgroundImage: "url('../best8.jfif')",backgroundSize: "cover", backgroundRepeat: "no-repeat", padding:"10px 0 10px 0"}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
           <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
    <div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
        <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
          <p>
            Home
          </p>
        </Link>
        {/* <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
        <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>IPO</p>
        </Link> */}
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
        <Link to={`/rights-issues`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>Rights Issues</p>
        </Link>
      </div>
      <br/>
    
      <h1 style={{marginTop:"-20px"}}>Rights Issues 2024</h1>
      <p style={{marginTop:"-10px", textAlign: "justify",fontSize:"15px"}}>Stay informed about the latest rights issues in India for 2024. Understand how companies are raising capital through rights issues, explore the benefits and risks for shareholders, and learn how to take advantage of these opportunities to strengthen your investment portfolio.</p>
      {/* <h4 style={{marginTop:"10px"}}>A "Current IPO" refers to an Initial Public Offering that is currently open for subscription or recently closed but not yet listed on a stock exchange. It represents a pivotal moment for a private company transitioning to a publicly traded entity, offering shares to investors for the first time to raise capital.</h4> */}
      {/* <p style={{marginTop:"0px", textAlign: "justify",fontSize:"15px"}}>List of all current IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Stay updated on the latest IPOs and their specifics in one place.</p> */}
      </div>
      </div>
      </div>
      <br/>
      {/* <br/> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

{allRightsIssues && allRightsIssues?.length > 0 ? ( <>
           <div style={{ width: '93%', maxWidth: '1200px' ,  display: "flex",
          flexDirection: "row",flexWrap:"wrap",gap:"20px" }}>
      
     <div style={{ 
         width:"100%",
        //  maxWidth:"900px",minWidth:"250px",
        // width:"auto",
         height:"auto",display:"flex",flexDirection:"column",gap:"10px"}}>
     
    {/* {allBuybacks && allBuybacks.length > 0 ? ( <> */}


    {allRightsIssues && allRightsIssues.length > 0 ? (
        <>
          {/* Filter Buttons */}
          {/* <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', margin: '0 0 10px 0' }}>
            <button className="filter-btn" onClick={() => setSelectedFilter('All')}>All Buybacks</button>
            <button className="filter-btn" onClick={() => setSelectedFilter('Current')}>Current Buybacks</button>
            <button className="filter-btn" onClick={() => setSelectedFilter('Upcoming')}>Upcoming Buybacks</button>
            <button className="filter-btn" onClick={() => setSelectedFilter('Closed')}>Closed Buybacks</button>
          </div> */}
          {/* <div className="button-containers" style={{borderBottom:"1px solid #ccc",paddingBottom:"10px"}}>
  <button className="filter-btns" onClick={() => setSelectedFilter('All')}>All</button>
  <button className="filter-btns" onClick={() => setSelectedFilter('Current')}>Current</button>
  <button className="filter-btns" onClick={() => setSelectedFilter('Upcoming')}>Upcoming</button>
  <button className="filter-btns" onClick={() => setSelectedFilter('Closed')}>Closed</button>
</div> */}

<div className="button-containers" style={{borderBottom: "1px solid #ccc", paddingBottom: "10px"}}>
      
      <button
        className={`filter-btns ${selectedFilter === 'Current' ? 'active' : ''}`}  // Add 'active' class if 'Current' is selected
        onClick={() => {setSelectedFilter('Current');
          setVisibleCount(10);
        }}
      >
        Current
      </button>
      <button
        className={`filter-btns ${selectedFilter === 'Upcoming' ? 'active' : ''}`}  // Add 'active' class if 'Upcoming' is selected
        onClick={() => {setSelectedFilter('Upcoming');
          setVisibleCount(10);
        }}
      >
        Upcoming
      </button>
      <button
        className={`filter-btns ${selectedFilter === 'Closed' ? 'active' : ''}`}  // Add 'active' class if 'Closed' is selected
        onClick={() => {setSelectedFilter('Closed');
          setVisibleCount(10);
        }}
      >
        Closed
      </button>
      <button
        className={`filter-btns ${selectedFilter === 'All' ? 'active' : ''}`}  // Add 'active' class if 'All' is selected
        onClick={() => {setSelectedFilter('All');
          setVisibleCount(10);
        }}
      >
        All
      </button>
    </div>


          <div >
            <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', gap: '15px' }}>


            {filteredRightsIssues.length > 0 ?(<>{filteredRightsIssues?.slice(0, visibleCount).map((issue) => (
        <div key={issue.id} className="card-body gap-3">
          <Link to={`/rights-issue/${issue.company_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
          <div style={{borderBottom:"1px solid #ccc",display:"flex",flexDirection:"row",alignItems:"center", justifyContent: "space-between",gap:"10px",flexWrap:"wrap",paddingBottom:"10px"}}>
            <div className="top_row" >
      
              <div className="header">
                <img
                  src={issue.image_url}
                  alt={issue.company_name}
                  style={{
                    height: '70px',
                    objectFit: 'contain',
                    width: '70px',
                  }}
                  loading="lazy"
                />
              </div>
              <h4 className="ipo_name px-1">{issue.company_name}</h4>
              </div>
              {/* {!isMobile && (  */}
                <div className="d-flex flex-wrap gap-2 flex-grow-1" style={{margin:"0px 0px 0px 0px",alignItems:"center"}}>
                {(currentDate >= stripTime(new Date(issue?.issue_open)) && currentDate <= stripTime(new Date(issue?.issue_close))) && (  <div style={{ fontSize: "14px", margin: "0px 5px 0px 0px", display: "flex", alignItems: "center", gap: "10px" }}>
    <label style={{ color: "#e13803", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#ffe9eb", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
     
      <img src="/Dott.gif" style={{ width: "10px", height: "10px", margin: "1px 3px 0px 0px"}}/>
      Live
    </label>
  </div>)}
  
  {(currentDate < stripTime(new Date(issue?.issue_open)) || issue?.issue_open === null) && (<div style={{ fontSize: "14px", margin: "0px 5px 0px 0px", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#267ca7", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#daeef7", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#267ca7", margin: "1px 5px 0px 0px" }}></div>
          Upcoming
        </label>
      </div>)}
    {(currentDate > stripTime(new Date(issue?.issue_close)) && issue?.issue_open !== null) && (<div style={{ fontSize: "14px", margin: "0px 5px 0px 0px", display: "flex", alignItems: "center", gap: "10px" }}>
        <label style={{ color: "#dd8f00", padding: "2px 10px", borderRadius: "5px", backgroundColor: "#fdf5e6", textDecoration: "none", display: "flex", alignItems: "center", width: "auto" }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: "#dd8f00", margin: "1px 5px 0px 0px" }}></div>
          Closed
        </label>
      </div>)}  
      {/* {lastValue && (<div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='#888'/><div style={{color:"#888"}}>Subs:</div><div style={{color:"rgb(107, 128, 179)"}}>{lastValue}</div></div>)} */}
              {/* <span className="badge_span">
                <i className="fa fa-check-circle" aria-hidden="true"></i> Tender Offer
              </span> */}
              <Link to={`/rights-issue/${issue.company_route_name}`} className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" style={{textAlign:"center",}}>
                View Details
              </Link>
            </div>
            {/* )} */}
            </div>
          </Link>

          <div className="broker_content">
            {/* <div className="d-flex flex-wrap gap-2 flex-grow-1">
    
              <Link to={`/rights-issue/${issue.company_route_name}`} className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto">
                View Details
              </Link>
            </div> */}

            {/* <Link to={`/rights-issue/${issue.company_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
              <div className="d-flex flex-wrap otherStatus mt-4">
                <div className="data_box">
                  <p className="label">Record Date</p>
                  <span data-role="charges">{new Date(issue.record_date).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Open</p>
                  <span data-role="charges">{new Date(issue.issue_open).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Rights Renunciation</p>
                  <span data-role="charges">{new Date(issue.renunciation_of_rights_entitlements).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Close</p>
                  <span data-role="charges">{new Date(issue.issue_close).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue price</p>
                  <span data-role="charges">{issue.issue_price} per share</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Size</p>
                  <span data-role="charges">{issue.issue_size_rs_cr} Cr</span>
                </div>
                <div className="data_box">
                  <p className="label">CMP (Share)</p>
                  <span data-role="charges">{issue.cmp_of_share}</span>
                </div>
                <div className="data_box">
                  <p className="label">CMP (Rights)</p>
                  <span data-role="charges">{issue.cmp_of_rights_entitlement}</span>
                </div>
                <div className="data_box">
                  <p className="label">Rights Issue Ratio</p>
                  <span data-role="charges">{issue.rights_issue_ratio}</span>
                </div>
              </div>
            </Link> */}

<Link 
      to={`/rights-issue/${issue.company_route_name}`} 
      className="link-style"
      // onMouseOver={e => {
      //   e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
      //   e.currentTarget.style.transform = 'scale(1.02)';
      // }}
      // onMouseOut={e => {
      //   e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
      //   e.currentTarget.style.transform = 'scale(1)';
      // }}
    >
      <div className="grid-container">
        <div className="grid-item">
          <p className="label1">Record Date</p>
          <span className="value1">{new Date(issue.record_date).toDateString() === "Thu Jan 01 1970" ? "- - - -" : new Date(issue.record_date).toDateString()}</span>
        </div>
        <div className="grid-item">
          <p className="label1">Issue Open</p>
          <span className="value1">{new Date(issue.issue_open).toDateString() === "Thu Jan 01 1970" ? "- - - -" : new Date(issue.issue_open).toDateString()}</span>
        </div>
        <div className="grid-item">
          <p className="label1">Rights Renunciation</p>
          <span className="value1">{new Date(issue.renunciation_of_rights_entitlements).toDateString() === "Thu Jan 01 1970" ? "- - - -" : new Date(issue.renunciation_of_rights_entitlements).toDateString()}</span>
        </div>
        <div className="grid-item">
          <p className="label1">Issue Close</p>
          <span className="value1">{new Date(issue.issue_close).toDateString() === "Thu Jan 01 1970" ? "- - - -" : new Date(issue.issue_close).toDateString()}</span>
        </div>
        <div className="grid-item">
          <p className="label1">Issue Price</p>
          <span className="value1">{issue.issue_price === "" ? "-" : issue.issue_price} per share</span>
        </div>
        <div className="grid-item">
          <p className="label1">Issue Size</p>
          <span className="value1">{issue.issue_size_rs_cr === "" ? "-" : issue.issue_size_rs_cr} Cr</span>
        </div>
        <div className="grid-item">
          <p className="label1">CMP (Share)</p>
          <span className="value1">{issue.cmp_of_share === "" ? "- - - -" : issue.cmp_of_share}</span>
        </div>
        <div className="grid-item">
          <p className="label1">CMP (Rights)</p>
          <span className="value1">{issue.cmp_of_rights_entitlement === "" ? "- - - -" : issue.cmp_of_rights_entitlement}</span>
        </div>
        <div className="grid-item">
          <p className="label1">Rights Issue Ratio</p>
          <span className="value1">{issue.rights_issue_ratio === "" ? "- - - -" : issue.rights_issue_ratio}</span>
        </div>
      </div>
    </Link>






            {/* <Link to={`/rights-issue/${issue.company_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
              <div className="d-flex flex-wrap otherStatus mt-4">
                <div className="data_box">
                  <p className="label">Record Date</p>
                  <span data-role="charges">{new Date(issue.record_date).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Open</p>
                  <span data-role="charges">{new Date(issue.issue_open).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Renunciation of Rights Entitlements</p>
                  <span data-role="charges">{new Date(issue.renunciation_of_rights_entitlements).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Close</p>
                  <span data-role="charges">{new Date(issue.issue_close).toDateString()}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue price (Per Share)</p>
                  <span data-role="charges">{issue.issue_price}</span>
                </div>
                <div className="data_box">
                  <p className="label">Issue Size (Rs Cr)</p>
                  <span data-role="charges">{issue.issue_size_rs_cr}</span>
                </div>
                <div className="data_box">
                  <p className="label">CMP of Share</p>
                  <span data-role="charges">{issue.cmp_of_share}</span>
                </div>
                <div className="data_box">
                  <p className="label">CMP of Rights Entitlement</p>
                  <span data-role="charges">{issue.cmp_of_rights_entitlement}</span>
                </div>
                <div className="data_box">
                  <p className="label">Rights Issue Ratio</p>
                  <span data-role="charges">{issue.rights_issue_ratio}</span>
                </div>
              </div>
            </Link> */}
          </div>
        </div>
      ))}</>):(<div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:"5px",marginBottom:"30px"}}><img src="../../hand-drawn-no-data-illustration.avif" alt="" width="250px" height="250px" /><div style={{fontSize:"16px",textAlign:"center"}}><strong>No rights issues available at the moment. Check back again soon!</strong></div><div style={{fontSize:"14px",textAlign:"center"}}>"When companies offer rights issues, they are reinforcing their commitment to growth and raising capital for future success."</div></div>)} 

      {/* Show 'View More' if there are more items to load */}
      {visibleCount < filteredRightsIssues.length && filteredRightsIssues.length > 0 && (
        <div className="text-center mt-3" style={{textAlign:"center"}}>
          <button className="btn view-more-btns" onClick={handleViewMore}>
            View More
          </button>
        </div>
      )}

      {/* Show 'Show Less' only when all items are visible */}
      {visibleCount >= filteredRightsIssues.length && filteredRightsIssues.length > 0 && visibleCount > 10 && (
        <div className="text-center mt-3" style={{textAlign:"center"}}>
          <button className="btn show-less-btns" onClick={handleShowLess}>
            Show Less
          </button>
        </div>
      )}

      
              {/* {filteredBuybacks.map((buyback) => (
                <div key={buyback.id} className="card-body gap-3">
                  <Link to={`/rights-issue/${buyback.company_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
                    <div className="top_row">
                      <div className="ipo_cover pb-3">
                        <img
                          src={buyback.image_url}
                          alt={buyback.company_name}
                          style={{
                            height: '100px',
                            objectFit: 'contain',
                            width: '100px',
                          }}
                          loading="lazy"
                        />
                      </div>
                      <h4 className="ipo_name px-1">{buyback.company_name}</h4>
                    </div>
                  </Link>

                  <div className="broker_content">
                    <div className="d-flex flex-wrap gap-2 flex-grow-1">
                      <span className="badge_span">
                        <i className="fa fa-check-circle" aria-hidden="true"></i> Tender Offer
                      </span>
                      <Link to={`/rights-issue/${buyback.company_route_name}`} className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto">
                        View Details
                      </Link>
                    </div>

                    <Link to={`/rights-issue/${buyback.company_route_name}`} style={{ textDecoration: 'none', color: 'black' }}>
                      <div className="d-flex flex-wrap otherStatus mt-4">
                        <div className="data_box">
                          <p className="label">Record Date</p>
                          <span data-role="charges">{new Date(buyback.record_date).toDateString()}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Issue Date</p>
                          <span data-role="charges">{new Date(buyback.issue_date).toDateString()}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Close Date</p>
                          <span data-role="charges">{new Date(buyback.close_date).toDateString()}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Buyback Price</p>
                          <span data-role="charges">{buyback.buyback_price}</span>
                        </div>
                        <div className="data_box">
                          <p className="label">Issue Size</p>
                          <span data-role="charges">{buyback.issue_size.split(' ')[0]}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))} */}
            </div>

            {/* Sidebar Brokers */}
            {/* <div style={{ width: 'auto', height: 'auto' }}>
              <div className="sideBoxContainer">
                {shuffledBrokers?.map((broker) => (
                  <a className="sideBox fadeUp" href={broker.Open_Demat_Account_Url} target="_blank" title={broker.Broker_Name} rel="noopener noreferrer">
                    <p className="cardTitle" style={{ textDecoration: 'none', color: 'black', marginBottom: '10px' }}>{broker.Broker_Name}</p>
                    <img src={broker.Broker_Banner} alt={broker.Broker_Name} className="cardImage" loading="lazy" style={{ marginBottom: '15px' }} />
                    <button className="btn knowMoreBtn" type="button">Know More</button>
                  </a>
                ))}
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <div className="loading-container">
          <div className="spinner"></div>
          <div>Fetching data, please hold on...</div>
        </div>
      )}








    {/* {allBuybacks?.map((buyback) => (
      <div key={buyback.id} className="card-body gap-3">
      <Link to={`/rights-issue/${buyback.company_route_name}`} style={{textDecoration:"none",color:"black"}}>
      <div className="top_row">
        <div className="ipo_cover pb-3">
          <img
            src={buyback.image_url}
            alt={buyback.company_name}
            // maxWidth="100px"
            // width="100%"
            // height="100px"
            loading="lazy"

            style={{ 
                // width: 100%;
                height: "100px",
                objectFit: "contain",
                width:"100px",


            // maxWidth: "200px",
            // minWidth: "100px",
            // height: "120px",
            // borderRadius: "8px",
            // overflow: "hidden",
            // display: "flex",
            // alignItems: "flex-start"
        }}
           
          />
        </div>
        <h4 className="ipo_name px-1" >{buyback.company_name}</h4>
      </div></Link>
      <div className="broker_content">
        <div className="d-flex flex-wrap gap-2 flex-grow-1" onClick={()=>handleDivClick(`/rights-issue/${buyback.company_name?.toLowerCase().replace(" ","-")}`)}>
       
          <span className="badge_span">
            <i className="fa fa-check-circle" aria-hidden="true"></i> Tender Offer
          </span>
     
          
         
          <a onClick={(event) => handleButtonClick(event)}  className="btn viewBtn outlinedGlowBtn align-self-center me-2 ms-auto" href={`/rights-issue/${buyback.company_name?.toLowerCase().replace(" ","-")}`}>
            View Details
          </a>
         
        </div>
        <Link to={`/rights-issue/${buyback.company_route_name}`} style={{textDecoration:"none",color:"black"}}>
        <div className="d-flex flex-wrap otherStatus mt-4">
          <div className="data_box">	
            <p className="label">Record Date</p>
            <span data-role="charges">{new Date(buyback.record_date).toDateString()}</span>
          </div>
          <div className="data_box">
            <p className="label">Issue Date</p>
            <span data-role="charges">{new Date(buyback.issue_date).toDateString()}</span>
          </div>
          <div className="data_box">
            <p className="label">Close Date</p>
            <span data-role="charges">{new Date(buyback.close_date).toDateString()}</span>
          </div>
          <div className="data_box">
            <p className="label">Buyback Price</p>
            <span data-role="charges">{buyback.buyback_price}</span>
          </div>
          <div className="data_box">
            <p className="label">Issue Size</p>
            <span data-role="charges">{buyback.issue_size.split(" ")[0]}</span>
          </div>
        </div></Link>
      </div>
    </div> ))} */}
   
    


   

    
      </div>

      {/* <div style={{ 
    //    minWidth:"200px",
    width:"auto",
    height:"auto" }}>
   <div  className="sideBoxContainer">
   {shuffledBrokers?.map((broker) => (
    <a className="sideBox fadeUp" 
       href={broker.Open_Demat_Account_Url} 
       target="_blank" 
       title={broker.Broker_Name} 
       rel="noopener noreferrer" >
       
      <p className="cardTitle" style={{textDecoration:"none",color:"black",marginBottom:"10px"}}>{broker.Broker_Name}</p>
      <img 
        src={broker.Broker_Banner} 
        alt={broker.Broker_Name} 
        className="cardImage" 
        loading="lazy" 
        style={{marginBottom:"15px"}}
      />
      <button 
        className="btn knowMoreBtn" 
        // role="button" 
        type="button">
        Know More
      </button>
    </a>))}
 
    </div>
     
   

      


     
      </div> */}

      
      </div>
      </>):(<div className="loading-container">
          <div className="spinner"></div>
          <div>Fetching data, please hold on...</div>
        </div>)}

      <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
        <br/>
        </div>

        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "93%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent type="Rights Issues" /></div></div>
     
    </>
  );
}

export default RightsIssues;
