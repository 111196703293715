
import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate, Link } from 'react-router-dom';
import '../IPOGMP.css'; // Make sure you have this CSS file
import { SlArrowRight } from "react-icons/sl";
import { hostInfo , token } from '../server';
import FaqComponent from './FaqComponent'; 
import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

// Global Filter Component
// const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
//   <TextField
//     value={globalFilter || ''}
//     onChange={(e) => setGlobalFilter(e.target.value)}
//     placeholder="Search Issuer Company"
//     variant="outlined"
//     margin="normal"
//     fullWidth
//   />
// );

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <TextField
    value={globalFilter || ''}
    onChange={(e) => setGlobalFilter(e.target.value)}
    placeholder="Search Issuer Company"
    variant="outlined"
    margin="normal"
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      style: { height: 40, fontSize: 14 }, // Adjust height and font size
    }}
    style={{ maxWidth: 500 }} // Control overall width
  />
);


// Function to count the number of matching words
const countMatchingWords = (searchWords, text) => {
  const textWords = text.split(' ');
  return searchWords.filter(word => textWords.includes(word)).length;
};

// Function to find the array with the most matching words
const findArrayWithMostMatchingWords = (searchValue, outputs) => {
  const searchWords = searchValue.split(' ');

  let maxCount = 0;
  let bestMatch = null;

  outputs.forEach(arr => {
    const text = arr[0]; // Assuming each array has a single string
    const count = countMatchingWords(searchWords, text);

    if (count > maxCount) {
      maxCount = count;
      bestMatch = arr;
    }
  });

  return bestMatch;
};

function IPOPerformance({ allIPOsPerformance }) {

  const faqData = [
    {
      question: "What is IPO performance?",
      answer: "IPO performance refers to how a company's stock performs in the market after its initial public offering (IPO), including its price movement, investor returns, and overall market reception."
    },
    {
      question: "How is IPO performance measured?",
      answer: "IPO performance is typically measured by comparing the IPO listing price with the closing price on the first day of trading, as well as tracking the stock's performance over time."
    },
    {
      question: "What factors affect the performance of an IPO?",
      answer: "Several factors influence IPO performance, including the company's financial health, industry trends, market conditions, investor sentiment, and the overall economic environment."
    },
    {
      question: "Why do some IPOs perform better than others?",
      answer: "IPOs perform differently based on factors like the company's growth potential, market demand, the pricing of the IPO, and investor confidence. Strong fundamentals and positive market conditions can lead to better performance."
    },
    {
      question: "What is the significance of the IPO listing day performance?",
      answer: "The IPO listing day performance is significant as it reflects initial investor interest and market sentiment towards the company's shares. A strong performance can boost investor confidence, while a weak performance may signal concerns."
    },
    {
      question: "How can I track the performance of an IPO after it lists?",
      answer: "You can track IPO performance by monitoring the stock's price movements, reading financial news, and analyzing reports on the company's earnings and market position. Many financial websites and apps provide real-time tracking tools."
    },
    {
      question: "What is considered a successful IPO?",
      answer: "A successful IPO is generally one where the company's shares trade above the IPO price on the listing day and continue to perform well in the market over time, providing good returns for investors."
    },
    {
      question: "Can an IPO's performance change over time?",
      answer: "Yes, an IPO's performance can change over time due to factors like changes in the company's business, market trends, economic conditions, and broader investor sentiment. Long-term performance is often more telling than initial results."
    },
    {
      question: "What are listing gains in an IPO?",
      answer: "Listing gains refer to the profit made when an investor sells shares on the stock market at a price higher than the IPO issue price. It's a key measure of short-term IPO performance."
    },
    {
      question: "Why might an IPO underperform after listing?",
      answer: "An IPO might underperform due to overvaluation, market volatility, poor company performance, or broader economic challenges. Sometimes, investor expectations are not met, leading to a decline in the stock price."
    },
    {
      question: "How do market conditions affect IPO performance?",
      answer: "Market conditions, such as economic stability, investor confidence, and industry trends, significantly affect IPO performance. A strong market can boost IPO success, while a weak market can dampen performance."
    },
    {
      question: "What is the long-term significance of IPO performance?",
      answer: "Long-term IPO performance indicates the company's ability to grow and generate profits for investors. Consistent growth and strong financial performance post-IPO can lead to sustained stock price increases."
    },
    {
      question: "How can I assess the potential performance of an upcoming IPO?",
      answer: "To assess potential performance, review the company's financials, growth prospects, industry position, and the market's overall health. Analyst reports and IPO roadshows can also provide valuable insights."
    },
    {
      question: "What are the risks of investing in an IPO based on its expected performance?",
      answer: "Investing based on expected performance carries risks like market volatility, incorrect valuation, and company-specific issues. It's important to conduct thorough research and diversify investments to mitigate risks."
    },
    {
      question: "How can I improve my chances of investing in a high-performing IPO?",
      answer: "Improving your chances involves researching the company's fundamentals, understanding market trends, following expert analyses, and being cautious about overhyped IPOs. Diversification and a long-term view can also help."
    }
  ];

  
  console.log(allIPOsPerformance);
  const [allIPOs, setAllIPOs] = useState([]);

  useEffect(() => {
    fetch(`${hostInfo}/search_ipo`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then((data)=>{setAllIPOs(data);})
  }, []);

  

  const [sortedData, setSortedData] = useState([]);
 
    // Function to format Date object to desired string format
const formatDate = (date) => {
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

// Function to decode encoded characters in an object
function decodeObject(obj) {
  // Helper function to replace encoded characters in strings
  function decodeString(str) {
      return str.replace(/â¹/g, '₹');
  }

  // Recursive function to traverse and decode the object
  function recursiveDecode(value) {
      if (typeof value === 'string') {
          return decodeString(value);
      }
      if (Array.isArray(value)) {
          return value?.map(item => recursiveDecode(item));
      }
      if (value !== null && typeof value === 'object') {
          const decodedObject = {};
          for (const [key, val] of Object.entries(value)) {
              decodedObject[key] = recursiveDecode(val);
          }
          return decodedObject;
      }
      return value; // If it's not a string, array, or object, return the value as is
  }

  return recursiveDecode(obj);
}

  useEffect(() => {
    const sorted = allIPOsPerformance
      ?.map(item => ({
        ...item,
        Listed_On_Date: new Date(item.Listed_On)
      }))
      .sort((a, b) => b.Listed_On_Date - a.Listed_On_Date)
      ?.map(item => {
        const { Listed_On_Date, ...rest } = item;
        return rest;
      });

      // Decode the array of objects
      const decodedArray = sorted?.map(decodeObject);

      console.log(decodedArray);

      setSortedData(decodedArray);

  }, [allIPOsPerformance]); // Only run effect if `data` changes

// // Function to count the number of matching words
// const countMatchingWords = (searchWords, text) => {
//   const textWords = text.split(' ');
//   return searchWords.filter(word => textWords.includes(word)).length;
// };

// // Function to find the array with the most matching words
// const findArrayWithMostMatchingWords = (searchValue, outputs) => {
//   const searchWords = searchValue?.split(' ');

//   let maxCount = 0;
//   let bestMatch = null;

//   outputs?.forEach(arr => {
//     const text = arr[0]; // Assuming each array has a single string
//     const count = countMatchingWords(searchWords, text);

//     if (count > maxCount) {
//       maxCount = count;
//       bestMatch = arr;
//     }
//   });

//   return bestMatch;
// };


// const valuesss="V.L.Infraprojects Limited";
// const link = findArrayWithMostMatchingWords(valuesss, allIPOs);
//         console.log(link);
// // Function to find the array containing the specific value
// const findArrayContainingValue = (searchValue) => {
//   return allIPOs.find(arr => arr.some(item => item.includes(searchValue)));
// };
// // Sort the ipoData array by the Listed_On date in descending order
// const sortedIpoData = allIPOsPerformance
//   .map(ipo => ({
//     ...ipo,
//     Listed_On_Date: new Date(ipo.Listed_On) // Add a Date object for sorting
//   }))
//   .sort((a, b) => b.Listed_On_Date - a.Listed_On_Date) // Sort in descending order
//   .map(ipo => {
//     // Remove the temporary Date object if needed
//     const { Listed_On_Date, ...rest } = ipo;
//     return rest;
//   });

console.log(sortedData);

  const navigate = useNavigate();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);

  const data = useMemo(() => sortedData, [sortedData]);

  const columns = useMemo(() => [
    {
      Header: 'Issuer Company',
      accessor: 'Company_Name',
      Cell: ({ value, row }) =>{
        const links = findArrayWithMostMatchingWords(value, allIPOs);
        const linkPath = links && `/ipo/${links[0].toLowerCase().replace(/ /g, '-')}`;

        console.log(value, links);
        
        return (
          <Link
            to={linkPath}
      //   const links = findArrayWithMostMatchingWords(value, allIPOs);
      //   console.log(value, links);
      //  return (
        
      //   <Link
      //   to={`/ipo/${links && links[0].toLowerCase().replace(/ /g, '-')}`}
      //     // to={`/ipo/${value.toLowerCase().replace(/ /g, '-')}-ipo` || `/ipo/${value.toLowerCase().replace(/ /g, '-')}-fpo` || `/ipo/${value.toLowerCase().replace(/ /g, '-')}-reit`}
          className={`issuer-link ${clickedRowIndex === row.index ? 'clicked' : ''}`}
        >
          {value}
        </Link>
      )}
    },
    { Header: 'IPO Type', accessor: 'IPO_Type',  
    Cell: ({ value }) => (
      // <span>
        <div  style={{color:"white",fontSize:"11px"}}>{value && value === "SME IPO" ? <label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid #7aa7db",borderRadius:"5px",backgroundColor:"#7aa7db",textDecoration:"none"}}>SME</label> :<label style={{color:"white",fontWeight:"lighter",padding:"1px 10px",border:"1px solid #6cb456",borderRadius:"5px",backgroundColor:"#6cb456",textDecoration:"none"}}>Mainstream</label>}</div>
      // </span>
    ) 
  },
    { Header: 'Listed On', accessor: 'Listed_On' },
    { Header: 'Issue Price', accessor: 'Issue_Price' },
    // {
    //   Header: 'GMP',
    //   accessor: 'GMP',
    //   Cell: ({ value }) => (
    //     <span style={{ color: value < 0 ? 'red' : 'inherit', fontWeight: "bold" }}>
    //       {value}
    //     </span>
    //   )
    // },
    { Header: 'Listing Day Close', accessor: 'Listing_Day_Close' },
    { Header: 'Listing Day Gain', accessor: 'Listing_Day_Gain',
      Cell: ({ value }) => (
        <span style={{ color: value.replace("%",'') < 0 ? 'red' : 'green'}}>
          {value}
        </span>
      ) },
    { Header: 'Current Price', accessor: 'Current_Price' },
    { Header: 'Profit Loss', accessor: 'Profit_Loss',
      Cell: ({ value }) => (
        <span style={{ color: value.replace("%",'') < 0 ? 'red' : 'green',fontWeight:"bold" }}>
          {value}
        </span>
      ) },
  ], [clickedRowIndex]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    { columns, data, initialState: { pageSize: 20 } },
    useGlobalFilter,
    usePagination
  );

  const handleRowClick = (rowIndex, row) => {
    setClickedRowIndex(row.index);
    const links = findArrayWithMostMatchingWords(row.original.Company_Name, allIPOs);
    const linkPath = links && `/ipo/${links[0].toLowerCase().replace(/ /g, '-')}`;

    console.log(row.original.Company_Name, links);
    navigate(linkPath);
    // navigate(`/ipo/${row.original.Company_Name.toLowerCase().replace(/ /g, '-')}-ipo` || `/ipo/${row.original.Company_Name.toLowerCase().replace(/ /g, '-')}-fpo` || `/ipo/${row.original.Company_Name.toLowerCase().replace(/ /g, '-')}-reit`);
    
  };

  return (
    <>
      {/* <Helmet>
        <title>Privacy Policy - Protecting Your Information at IPOtank</title>
        <meta name="description" content="At IPOtank, we respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website." />
        <meta name="keywords" content="Privacy Policy, personal information protection, data privacy, GDPR, CCPA, privacy practices" />
      </Helmet> */}
     <Helmet>
     <title>IPO Performance Tracker - Information about current value of Listed IPO</title>
     <meta name="description" content="Detailed performance data of listed companies from their listing date to now, issue prices, listing day close and gain, current prices, and profit/loss at IPO tank" />
     <meta name="keywords" content="IPO Performance Tracker, IPO data, Company Name, Listed On, Issue Price, Listing Day Close, Listing Day Gain, Current Price, Profit Loss, IPO Type, IPOtank, IPO tank" />
  {/* <meta name="author" content="IPOtank Team" /> */}
  <meta property="og:url" content="https://www.ipotank.com/ipo-performance" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="IPO Performance Tracker - Information about current value of Listed IPO" />
    <meta property="og:description" content="Detailed performance data of listed companies from their listing date to now, issue prices, listing day close and gain, current prices, and profit/loss at IPO tank" />
</Helmet>


      <div style={{ backgroundImage: "url('../best8.jfif')", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "10px 0 10px 0" }}>
      
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
          <div style={{ textAlign: 'left', display: "flex", alignItems: "left", gap: "15px", color: "#666", paddingTop: "25px" }} >
            <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }} >
              <p>Home</p>
            </Link>
            <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
            <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
              <p>IPO</p>
            </Link>
            <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
            <Link to={`/ipo-performance`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
              <p>Performance</p>
            </Link>
          </div>
          <br />
          {/* Title and Description */}
          <h1 style={{ marginTop: "-20px" }}>IPO Performance</h1>
          {/* <h3 style={{ marginTop: "30px" }}>The financial markets are on the move</h3> */}
          <p style={{ marginTop: "-10px", textAlign: "justify",fontSize:"15px"}}>Track and analyze IPO performance with detailed data on issuer companies, listing dates, issue prices, listing day close and gain, current prices, and profit/loss. Stay updated with comprehensive IPO data at IPOtank.</p>
        </div>
      </div>
    </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          // marginTop: "5px",
          // marginBottom: "5px",
          textAlign: "justify",
        }}
      >
        <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          <TableContainer component={Paper}>
            <Table {...getTableProps()}>
              <TableHead style={{ background: "linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%)" }}>
                {headerGroups?.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers?.map(column => (
                      <TableCell {...column.getHeaderProps()} style={{ color: "white", fontSize: "15px" }}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page?.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => handleRowClick(rowIndex, row)}
                      style={{ cursor: 'pointer' }}
                      className="table-row"
                    >
                      {row.cells?.map(cell => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                          {/* ?.replace("â¹","₹") */}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            page={pageIndex}
            onPageChange={(event, newPage) => gotoPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={e => setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 30]}
            nextIconButtonProps={{ disabled: !canNextPage }}
            backIconButtonProps={{ disabled: !canPreviousPage }}
          />
        </div>
      </div>

      <br/>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "90%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent type="Performance" /></div></div>

    </>
  );
}

export default IPOPerformance;
