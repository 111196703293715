import * as React from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
import { useEffect, useState } from "react";
// import { hostInfo } from "../../../service";
import ReactMarkdown from "react-markdown";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    paginate(pageNumber);
  };

  const renderPageNumbers = pageNumbers.map((number) => {
    const activeClass = number === currentPage ? 'active' : '';
    return (
      <li key={number} style={{ display: number >= currentPage - 2 && number <= currentPage + 2 ? 'inline-block' : 'none'}}>
        {/* <button onClick={() => handleClick(number)} className={`page-number ${activeClass}`}  style={{ background: activeClass ? 'white' : 'transparent',border: activeClass ? '1px solid rgb(255, 120, 71)' : '1px solid rgb(221, 220, 221)',color: activeClass ? 'rgb(255, 120, 71)' : '#666',padding:"8px 14px",borderRadius:"100%" ,fontSize:"15px",fontWeight:"bold"}}> */}
        <button
  onClick={() => handleClick(number)}
  className={`page-number ${activeClass}`}
  style={{
    background: 'transparent',
    border: activeClass ? '1px solid rgb(255, 120, 71)' : '1px solid rgb(221, 220, 221)',
    color: activeClass ? 'rgb(255, 120, 71)' : '#666',
    padding: "8px 0",
    borderRadius: "10px",
    fontSize: "15px",
    width:"40px",
    fontWeight: "bold",
    transition: "box-shadow 0.3s", /* Add transition for smooth hover effect */
    boxShadow: activeClass ? "inset 0 0 5px #f7916c" : "none", /* Apply inner shadow only when active */
  }}
  onMouseOver={(e) => {
   
    e.target.style.boxShadow = 'inset 0 0 5px #f7916c'; // Apply inner shadow on mouse over
  }}
  onMouseOut={(e) => {
  
    e.target.style.boxShadow = activeClass ? 'inset 0 0 5px #f7916c' : 'none'; // Apply or reset inner shadow based on active state
  }}
>
          {number}
        </button>
      </li>
    );
  });

  return (
    <nav>
      <ul style={{ display: 'flex', justifyContent: 'center', listStyle: 'none', padding: 0,gap:"5px" }}>
        <li className="page-item">
          <button
            onClick={() => handleClick(currentPage === 1 ? 1 : currentPage - 1)}
            disabled={currentPage === 1}
            style={{
            padding: "8px 0",
            borderRadius: "10px",
            width:"40px",
            fontSize: "15px",
            fontWeight: "bold", background: 'transparent',  border: currentPage === 1 ?  '1px solid rgb(221, 220, 221)':'1px solid rgb(255, 120, 71)',
            color: currentPage === 1 ? '':'rgb(255, 120, 71)',}}    onMouseOver={(e) => {
              if (currentPage !== 1) {
                e.target.style.boxShadow = 'inset 0 0 5px #f7916c'; // Apply inner shadow on mouse over
              }
            }}
            onMouseOut={(e) => {
              e.target.style.boxShadow = 'none'; // Remove inner shadow on mouse out
            }}
          >
            {/* Prev */}
            <svg
                  style={{ width: '0.80rem', height: '0.80rem', transform: 'scaleX(-1)',pointerEvents: 'none' }}
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 6 10'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='m1 9 4-4-4-4'
                  />
                </svg>
          </button>
        </li>
        {renderPageNumbers}
        <li className="page-item">
          <button
            onClick={() => handleClick(currentPage === pageNumbers.length ? pageNumbers.length : currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
            style={{
              padding: "8px 0",
            borderRadius: "10px",
            fontSize: "15px",
            width:"40px",
            fontWeight: "bold", background: 'transparent',  border: currentPage === pageNumbers.length ?  '1px solid rgb(221, 220, 221)':'1px solid rgb(255, 120, 71)',
            color: currentPage === pageNumbers.length ? '':'rgb(255, 120, 71)',}}  onMouseOver={(e) => {
   
              e.target.style.boxShadow = currentPage === pageNumbers.length ? '':'inset 0 0 5px #f7916c'; // Apply inner shadow on mouse over
            }}
            onMouseOut={(e) => {
            
              e.target.style.boxShadow = 'none'; // Apply or reset inner shadow based on active state
            }}
          >
            {/* Next */}
            <svg
                  style={{ width: '0.80rem', height: '0.80rem', transform: 'scaleX(-1)' ,pointerEvents: 'none'}}
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 6 10'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M5 1 1 5l4 4'
                  />
                </svg>
          </button>
        </li>
      </ul>
    </nav>
  );
};


function IPONews({blogs}) {
  console.log(blogs)

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs && blogs.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  


  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  }

 
  

  return (
    <>
   <Helmet>
   <title>Latest IPO News and Updates - IPOtank</title>
   <meta name="description" content="Stay informed with the latest IPO news from IPO tank. Explore upcoming, current, closed, and recently listed IPOs, along with expert analysis and insights." />
   <meta name="keywords" content="IPO news, IPO updates, upcoming IPOs, current IPOs, closed IPOs, recently listed IPOs, IPO analysis, IPOtank, IPO tank" />

   <meta property="og:url" content="https://www.ipotank.com/ipo-news" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Latest IPO News and Updates - IPOtank" />
    <meta property="og:description" content="Stay informed with the latest IPO news from IPO tank. Explore upcoming, current, closed, and recently listed IPOs, along with expert analysis and insights." />

</Helmet>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    > 
    <div style={{ width: '90%', maxWidth: '1200px' }}>
  <div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"10px"}} >
      <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
        <p>
          Home
        </p>
      </Link>
      <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
    
      <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        <p>IPO</p>
      </Link>
      <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      <Link to={`/ipo-news`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        <p>News</p>
      </Link>
    </div>
 
    </div>
    </div>

<div style={{ borderTop: "1px solid #dddcdd",marginBottom:"15px"}} ></div>

    <div
style={{
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}}
> 
    <div style={{ width: '90%', maxWidth: '1200px',marginBottom:"20px"}}>
    {/* <div style={{ display: 'grid',borderTop: "2px solid #dddcdd", gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',paddingTop:"20px"}}> */}
      {/* <div style={{display:"flex",flexWrap:"wrap",flexDirection:"row"}}> */}


     

<div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"20px",justifyContent:"center",marginBottom:"20px"}}>
        {currentPosts && currentPosts.map((i, index)=>(
          i.RedirectToExternal === 0 ? ( 
            <Link to={`/news/ipo/${encodeURIComponent(i.Title.toLowerCase().replace(/ /g, '-'))}`} style={{cursor: 'pointer',textDecoration:"none"}} key={index}>
        <div  style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px" ,  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
    {/* // onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    // onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}> */}
        {/* ,boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)" */}
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image={i.ImageURL}
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h8" component="div" style={{color:"#333",fontWeight:"bold",wordWrap:"break-word"}}>
          {i.Title.length > 60 ? i.Title.substring(0, 60) + "..." : i.Title}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666",wordWrap:"break-word",fontSize:"11px"}}>
          
          {i.Summary.length > 100 ? i.Summary.substring(0, 100) + "..." : i.Summary}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"12px",color:"black"}}>
          Published by : {i.PublishedBy} | {formatDate(i.PostDate)}
          </Typography>
        </div>
      </div>
    </div>
    </Link>):( <Link to={i.ExternalBlogLink} style={{cursor: 'pointer',textDecoration:"none"}} target="_blank" key={index}>
        <div  style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px" ,  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
    {/* // onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    // onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}> */}
        {/* ,boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)" */}
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image={i.ImageURL}
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h8" component="div" style={{color:"#333",fontWeight:"bold",wordWrap:"break-word"}}>
          {i.Title.length > 60 ? i.Title.substring(0, 60) + "..." : i.Title}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666",wordWrap:"break-word",fontSize:"11px"}}>
          
          {i.Summary.length > 100 ? i.Summary.substring(0, 100) + "..." : i.Summary}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"12px",color:"black"}}>
          Published by : {i.PublishedBy} | {formatDate(i.PostDate)}
          </Typography>
        </div>
      </div>
    </div>
    </Link>)))}
    {/* <Link to={`/news/ipo/${encodeURIComponent(i.Title.toLowerCase().replace(/ /g, '-'))}`} style={{cursor: 'pointer',textDecoration:"none"}} key={index}>
        <div  style={{maxWidth: "340px", zIndex:"-3",  border: "1px solid rgb(221, 220, 221)", borderRadius:"20px" ,  transition: "box-shadow 0.3s",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
    }}
    onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 20px rgba(0, 0, 0, 0.5)"}
    onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>
   
      <div style={{borderRadius:"20px"}}>
        <CardMedia
          component="img"
          height="150"
          // width="150"
          image={i.ImageURL}
          alt="green iguana"
          style={{borderRadius:"20px 20px 0 0"}}
        />
        <div style={{margin:"10px 15px"}}>
          <Typography gutterBottom variant="h8" component="div" style={{color:"#333",fontWeight:"bold",wordWrap:"break-word"}}>
          {i.Title.length > 60 ? i.Title.substring(0, 60) + "..." : i.Title}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{color:"#666",wordWrap:"break-word",fontSize:"11px"}}>
          
          {i.Summary.length > 100 ? i.Summary.substring(0, 100) + "..." : i.Summary}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{marginTop:"10px",fontSize:"12px",color:"black"}}>
          Published by : {i.PublishedBy} | {formatDate(i.PostDate)}
          </Typography>
        </div>
      </div>
    </div>
    </Link> */}

     
      
      </div>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={blogs.length}
        paginate={paginate}
      />
      
     {/* Advertisement */}
    <div style={{ gridColumn: 'span 2', marginBottom: '2.5rem',marginTop:"1.5rem"}}>
      <div style={{ border: "1px dashed rgb(221, 220, 221)", padding: '1rem', color: "#666", borderRadius: '0.375rem', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        <h6>ADVERTISEMENT</h6>
      </div>
    </div>
      </div>
      {/* </div> */}
    </div>

 
</>
    
  );
};
  export default IPONews;