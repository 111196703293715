import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { Paper, Typography, Card, CardContent, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { MdSubscriptions } from "react-icons/md";
import '../banner.css';
import ConfirmDialog from './ConfirmDialog';
import IPOStatusComponent from "./IPOStatusComponent";
import FaqComponent from './FaqComponent'; 

const IPOComponent = ({ targetString, ipoPerformanceData, row11, row12 }) => {
  // console.log("ipoPerformanceData",ipoPerformanceData);
  
  // const targetWords = new Set((targetString || '').toLowerCase().split(' '));

  // const countMatchingWords = (companyName) => {
  //   const companyWords = new Set(companyName.toLowerCase().split(' '));
  //   const intersection = [...targetWords].filter(word => companyWords.has(word));
  //   return intersection.length;
  // };

  // const matchingRows = ipoPerformanceData.map(row => ({
  //   ...row,
  //   Matching_Words: countMatchingWords(row.Company_Name)
  // }));

  // const maxMatchingWords = matchingRows.length > 0 ? Math.max(...matchingRows.map(row => row.Matching_Words)) : 0;
  
  // const bestMatchRow = matchingRows.find(row => row.Matching_Words === maxMatchingWords) || {};

  // const { Company_Name, Current_Price, Profit_Loss } = bestMatchRow;


  console.log("ipoPerformanceData", targetString);
  
  const targetWords = new Set((targetString || '').toLowerCase().split(' '));

  const countMissingWords = (companyName) => {
    const companyWords = new Set(companyName?.toLowerCase().split(' '));
    const missingWords = [...targetWords].filter(word => !companyWords.has(word));
    return missingWords.length;
  };

  const matchingRows = ipoPerformanceData?.map(row => {
    const missingWordsCount = countMissingWords(row.Company_Name);
    return {
      ...row,
      Missing_Words: missingWordsCount
    };
  });

  // Find the row with the least missing words, but allow at most one missing word
  const bestMatchRow = matchingRows?.find(row => row.Missing_Words <= 1) || {};

  const { Company_Name, Current_Price, Profit_Loss } = bestMatchRow.Missing_Words > 1 ? {} : bestMatchRow;
  console.log("ipoPerformanceData", Company_Name, Current_Price, Profit_Loss);

  return (
    <>
      {Profit_Loss && (Profit_Loss !== '' || Profit_Loss !== null) ? (
        <Link to={`/ipo/${targetString.toLowerCase().replace(/ /g, '-')}`} style={{ textDecoration: "none" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "3px 10px", fontSize: "14px", color: "#888", textAlign: "start" }}>
            <div style={{ marginRight: "5px",color: "#888" }}>Closing Price:</div>
            <div style={{ color: Profit_Loss.replace("%",'') < 0 ? 'red' : 'green' }}>
              {Current_Price.replace("â¹","₹")} (<span>{Profit_Loss}</span>)
            </div>
          </div>
        </Link>
      ):(row11 !== ('' || null) && (<><Link to={`/ipo/${targetString.toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", padding: "3px 10px", fontSize: "14px", color: "#888", textAlign: "start"}} 
    // style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
    >
    <div style={{marginRight:"5px",color: "#888"}}>Exp. Premium:</div>{row11 < 0 ? (<div style={{color:'red'}}> {row11} {row12.split(' ')[1]}</div>):(<div style={{color:'green'}}> {row11} {row12.split(' ')[1]}</div>)}
    </div></Link></>))}
    </>
  );
};





function RecentlyListedIPOs({recentlyListedIPOs,allIPOsPerformance}) {

  const faqData = [
    {
      question: "What is a recently listed IPO?",
      answer: "A recently listed IPO refers to a company that has recently gone public and its shares have started trading on the stock exchange. This usually happens within a few days after the IPO subscription period ends."
    },
    {
      question: "How can I check the performance of a recently listed IPO?",
      answer: "You can check the performance of a recently listed IPO by monitoring its stock price on the stock exchange, reviewing financial news, or using your broker's trading platform to track real-time data."
    },
    {
      question: "Why do share prices of a recently listed IPO fluctuate?",
      answer: "Share prices of a recently listed IPO can fluctuate due to market sentiment, demand and supply dynamics, investor perception of the company’s potential, and broader market conditions."
    },
    {
      question: "What should I do if the share price of a recently listed IPO drops below the issue price?",
      answer: "If the share price of a recently listed IPO drops below the issue price, you can either hold onto your shares, hoping for a price recovery, or consider selling them if you believe the stock may underperform."
    },
    {
      question: "Can I sell my shares on the listing day of a recently listed IPO?",
      answer: "Yes, you can sell your shares on the listing day of a recently listed IPO. However, it's essential to consider market conditions and whether you believe the stock price might rise or fall later."
    },
    {
      question: "What factors should I consider before selling a recently listed IPO?",
      answer: "Before selling a recently listed IPO, consider factors like the company's long-term growth potential, the current market conditions, and any news or developments that might affect the stock's future performance."
    },
    {
      question: "What is the lock-in period for anchor investors in a recently listed IPO?",
      answer: "The lock-in period for anchor investors in a recently listed IPO is typically 30 days from the date of allotment. During this time, anchor investors cannot sell their shares, which helps stabilize the stock price."
    },
    {
      question: "How does the lock-in period affect the stock price of a recently listed IPO?",
      answer: "The lock-in period can affect the stock price of a recently listed IPO by reducing the available supply of shares in the market. After the lock-in period ends, there might be increased selling pressure if anchor investors decide to sell."
    },
    {
      question: "How can I find out if a recently listed IPO has a good future outlook?",
      answer: "To assess if a recently listed IPO has a good future outlook, research the company’s business model, financial health, industry trends, management team, and any upcoming projects or innovations that could drive growth."
    },
    {
      question: "What is the significance of the listing day performance for a recently listed IPO?",
      answer: "The listing day performance of a recently listed IPO is significant because it reflects investor sentiment and initial market demand. However, it’s not always indicative of the stock’s long-term potential."
    },
    {
      question: "How can I participate in trading for a recently listed IPO?",
      answer: "You can participate in trading for a recently listed IPO through your stockbroker’s platform or any online trading portal. Make sure to monitor the market closely and set your buy/sell orders accordingly."
    },
    {
      question: "Should I invest more in a recently listed IPO after its performance stabilizes?",
      answer: "Investing more in a recently listed IPO after its performance stabilizes can be a good strategy if you believe in the company’s long-term growth potential. However, it’s important to conduct thorough research before making additional investments."
    },
    {
      question: "What happens if a recently listed IPO performs better than expected?",
      answer: "If a recently listed IPO performs better than expected, the stock price may rise, providing early investors with substantial gains. It also boosts the company's market perception and may attract more investors."
    },
    {
      question: "Can the performance of a recently listed IPO affect the broader market?",
      answer: "Yes, the performance of a large or highly anticipated recently listed IPO can impact the broader market, especially if it significantly influences investor sentiment or sector dynamics."
    },
    {
      question: "What should I watch for in the financial reports of a recently listed IPO?",
      answer: "In the financial reports of a recently listed IPO, watch for revenue growth, profitability, cash flow, debt levels, and any forward-looking statements from the management. These metrics can provide insights into the company's future performance."
    }
  ];
  
  
   // const [iconPills, setIconPills] = React.useState("1");
  // const [pills, setPills] = React.useState("1");
  const [ipoType, setIpoType] = useState("All IPO");

  // const handleClick = (value) => {
  //   window.open(value, '_blank');
  // };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const handleClick = (value) => {
      setExternalUrl(value);
      setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };

  const handleIpoTypeChange = (type) => {
    setIpoType(type);
    setActiveTab(type);
    setShowAllRows(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ff7847",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  }


  // Filter rows based on IPO type
  const filteredRows =
    ipoType === "All IPO"
      ? recentlyListedIPOs
      : recentlyListedIPOs.filter((row) => {
          if (ipoType === "Mainboard IPO") {
            const lastValue = row[8] && row[8].split(" ").pop();
            return lastValue === "NSE";
          } else if (ipoType === "SME IPO") {
            const lastValue = row[8] && row[8].split(" ").pop();
            return lastValue === "SME";
          }
        });

  const [showAllRows, setShowAllRows] = useState(false);
  const [activeTab, setActiveTab] = useState("All IPO");
  const initialRowCount = 6;

  const visibleRows = showAllRows ? filteredRows.length : initialRowCount;

  return (
    <>
   <Helmet>
   <title>Recently Listed IPO - Explore New Market Entrants | IPOtank</title>
   <meta name="description" content="Discover recently listed IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Track the latest market entrants and their performance. Visit IPOtank for comprehensive information and updates on recently listed IPOs." />
   <meta name="keywords" content="recently listed IPOs, IPO market entrants, IPO performance, IPO dates, IPO lot size, IPO price, IPO subscription, IPO allotment, stock market, investments, IPOtank, IPO tank" />

   <meta property="og:url" content="https://www.ipotank.com/recently-listed-ipo" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Recently Listed IPO - Explore New Market Entrants | IPOtank" />
    <meta property="og:description" content="Discover recently listed IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Track the latest market entrants and their performance. Visit IPOtank for comprehensive information and updates on recently listed IPOs." />
</Helmet>

      <div style={{backgroundImage: "url('../best8.jfif')",backgroundSize: "cover", backgroundRepeat: "no-repeat", padding:"10px 0 10px 0"}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
           <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
    <div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
        <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
          <p>
            Home
          </p>
        </Link>
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
        <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>IPO</p>
        </Link>
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
        <Link to={`/recently-listed-ipo`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>Recently Listed IPO</p>
        </Link>
      </div>
      <br/>
    
      <h1 style={{marginTop:"-20px"}}>Recently Listed IPO</h1>
      {/* <h3 style={{marginTop:"30px"}}>The financial markets are on the move</h3> */}
      <p style={{marginTop:"-10px", textAlign: "justify",fontSize:"15px"}}>"Recently Listed IPO" refers to an Initial Public Offering where the shares of a company have been successfully offered to the public and are now trading on a stock exchange.</p>
      <p style={{marginTop:"0px", textAlign: "justify",fontSize:"15px"}}>List of all recently listed IPOs with details like date, lot size, price, subscription, and allotment status at IPOtank. Track the latest market entrants and their performance.</p>
      </div>
      </div>
      </div>
      <br/>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
     <div style={{padding:"15px 15px",width: "85%",maxWidth:"1200px",minWidth:"100px",height:"auto",bolder:"1px solid #333", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
      
     <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px auto",
    flexWrap: "wrap", // Allow items to wrap to next line
    gap: "10px", // Gap between items
  }}
>
  <div
    style={{
      backgroundColor: activeTab === "All IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "All IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("All IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "All IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0, // Reset default margin
      }}
    >
      All IPO
    </p>
  </div>
  <div
    style={{
      backgroundColor: activeTab === "Mainboard IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "Mainboard IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("Mainboard IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "Mainboard IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0,
      }}
    >
      Mainboard IPO
    </p>
  </div>
  <div
    style={{
      backgroundColor: activeTab === "SME IPO" ? "#666" : "",
      textAlign:"center",
      padding: "5px 10px",
      minWidth: "100px", // Set a minimum width to prevent content from collapsing
      borderRadius: "30px",
      border: "2px solid #666",
      boxShadow: activeTab === "SME IPO" ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : "",
    }}
  >
    <p
      onClick={() => handleIpoTypeChange("SME IPO")}
      style={{
        cursor: "pointer",
        color: activeTab === "SME IPO" ? "white" : "#666",
        textDecoration: "none",
        margin: 0,
      }}
    >
      SME IPO
    </p>
  </div>
     </div>

      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          padding: "10px auto",
          // gap: "10px",
          // borderBottom:"2px solid #bdb4b8",
          // background: "green",
        }}
      >
        <div style={{
      backgroundColor:(activeTab === "All IPO") ? '#666' : '', padding:"0px 20px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "All IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("All IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "All IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "All IPO") ? '#ff7847' : '#666',
              color: (activeTab === "All IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            All IPO
          </p></div>
          <div style={{
      backgroundColor:(activeTab === "Mainboard IPO") ? '#666' : '', padding:"0px 25px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "Mainboard IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("Mainboard IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "Mainboard IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "Mainboard IPO") ? '#ff7847' : '#666',
              color: (activeTab === "Mainboard IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            Mainboard IPO
          </p>
          </div>
          <div style={{
      backgroundColor:(activeTab === "SME IPO") ? '#666' : '', padding:"0px 25px",width:"auto",borderRadius:"30px",bolder:"2px solid #333",boxShadow: (activeTab === "SME IPO") ? "0px 0px 20px rgba(0, 0, 0, 0.5)" : ''}}>
          <p
            onClick={() => handleIpoTypeChange("SME IPO")}
            // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
            // onMouseLeave={(e) => e.target.style.color = (activeTab === "SME IPO") ? '#ff7847' : '#666'}
            style={{
              // fontWeight: "bold",
              cursor: 'pointer',
              // color: (activeTab === "SME IPO") ? '#ff7847' : '#666',
              color: (activeTab === "SME IPO") ? 'white' : '#666',
              textDecoration: "none"
            }}
          >
            SME IPO
          </p>
          </div>   </div> */}
        {/* <p
          onClick={() => handleIpoTypeChange("All IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
        //   style={{ 
        //   color: ipoType === "All IPO" ? "red" : "inherit"
          
      
        // }}
       
        >
          All IPO
        </p>
        <p
          onClick={() => handleIpoTypeChange("Mainboard IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
          // style={{ color: ipoType === "Main IPO" ? "red" : "inherit" }}
        >
          Mainboard IPO
        </p>
        <p
          onClick={() => handleIpoTypeChange("SME IPO")}
          onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}
          // style={{ color: ipoType === "SME IPO" ? "red" : "inherit" }}
        >
          SME IPO
        </p> */}
   

      


      {/* <br/> */}
        {/* <h2 style={{ textAlign: 'left' ,fontSize: "20px",fontWeight: "bold"}}>Recently Listed IPO</h2> */}
        {filteredRows?.length > 0 ? (
          //  <Grid container spacing={2}>
            <div style={{maxWidth:"1200px",width:"100%",userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text',marginTop:"15px" }}>
           {filteredRows.slice(0, visibleRows).map((row, index) => (
            <>
            <div key={index} style={{marginBottom:"15px", width:"100%",border:"1px solid #dfdede",borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)"}}>
             {/* <Grid item xs={12} sm={6} md={12} key={index}> */}
               {/* <Card>
                 <CardContent> */}
                 <div style={{ display: 'flex', flexWrap: 'wrap',padding:"10px 10px" }}>
                  {/* <div style={{borderBottom:"1px solid #20B2AA" ,width:"100%",padding:"10px 0"}}>
  <Typography  component="div">
    <Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontSize:"15px", fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
      <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px", width: "50px" }} />
        {row[1]}
      </div>
      <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
      </div>
    </Link>
  </Typography>
                  </div> */}
                   <div style={{borderBottom:"1px solid #20B2AA" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", color: "#666", textDecoration: "none" }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
 {/* <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}> */}
 <div style={{ display: "flex" ,alignItems:"center" }}>
 <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
   <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px", width: "50px" }} />
   {row[1]}
 </div>
 {/* <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #20B2AA",borderRadius:"5px",backgroundColor:"#20B2AA",textDecoration:"none"}}>SME</label> :""}</div> */}
 <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
 </div>
 <div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/recently-listed-ipo`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div>
</div>
</Link>
</Typography>
<div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
<IPOComponent  targetString={row[1]} ipoPerformanceData={allIPOsPerformance} row11={row[11]} row12={row[12]} />
<div style={{marginLeft:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
        listingDate={new Date(row[4])}
      /></div></div>
{/* {row[13] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Closing Price:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)} */}

             </div>
                  <div style={{display: 'flex', flexDirection: 'row' ,width:"100%", flexWrap: 'wrap', padding:"10px 10px",justifyContent:"space-between",gap:"12px"}}>
   <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
   <div >
   <label style={{color:"#888"}}>Open Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {(row[2] !== null) ?( <strong>
      {new Date(row[2]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[3]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
     </strong> */}
   </div>
    </div>
 
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
   
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Close Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[3] !== null) ?( <strong>
      {new Date(row[3]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[4]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Listing Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[5] !== null) ?( <strong>
      {new Date(row[5]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Price</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {/* {(row[6] !== '') ? (<strong>₹ {row[6]}</strong>):<strong>------</strong>} */}
    {(row[5] !== '') ? (
  <strong>
    {row[5].includes('to') ? `₹ ${row[5].split('to')[1]}` : `₹ ${row[5]}`}
  </strong>
) : <strong>------</strong>}
     {/* <strong>
           
 ₹ {row[6]}
       </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[6] !== '') ? (<strong>₹ {row[6]} Cr</strong>):<strong>------</strong>}
     {/* <strong>
       
 ₹ {row[7]} Cr
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Lot Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[7] !== '') ? (<strong>{row[7]}</strong>):<strong>------</strong>}
     {/* <strong>
       {row[8]}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20}/><div>Subs</div></div></label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[9] !== null) ?( <strong>
      {row[9].split(' ')[0].replace(/[^\d,]/g, '')}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
      <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
     {row[9] !== null && row[9]?.includes('(') ? <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> : <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"rgb(107, 128, 179)"}}>Applications</div></div></label>}
   {/* <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> */}
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[9] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`}
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"90px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}} >Exchange</label>
   <div style={{marginTop:"5px",height:"auto"}}>
     <strong>
       {row[9]}
         </strong>
   </div>
    </div>
     </Typography>
     </div> */}
                 </div>
                 </div>
                 {/* </CardContent>
               </Card> */}
             {/* </Grid> */}
             </div>
             </>
           ))}
            {filteredRows.length > 6 ? (
           <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => setShowAllRows(!showAllRows)}
              className="py-0 text-left pl-8">
             {/* // <button */}
             {/* //   onClick={() => setShowAllRows(!showAllRows)}
             //   className="py-0 text-left pl-8"
             // > */}
               {showAllRows ? (
                 <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                 border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
            onMouseEnter={(e) => {
               e.target.style.borderColor = '#ff7847';
               e.target.style.color = '#ff7847';
             }} 
             onMouseLeave={(e) => {
               e.target.style.borderColor = '#666';
               e.target.style.color = '#666';
             }}>
                   Show Less 
                   {/* <TiArrowSortedUp/> */}
                 
                 </div>
               ) : (
                 <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                 border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
            onMouseEnter={(e) => {
               e.target.style.borderColor = '#ff7847';
               e.target.style.color = '#ff7847';
             }} 
             onMouseLeave={(e) => {
               e.target.style.borderColor = '#666';
               e.target.style.color = '#666';
             }}>
                   View More
                   {/* <TiArrowSortedDown /> */}
                   {/* <GoChevronDown/> */}
                  
                 </div>
               )}
            </div>):null}
           </div>
        //  </Grid>
        // <TableContainer
        //   style={{ width: "100%", maxWidth: "1200px"}}
        //     component={Paper}
        // >
        //   <Table sx={{ minWidth: 350 }} aria-label="customized table" >
           
        //       {filteredRows.length > initialRowCount && (
                //  <caption style={{ textAlign: "right", fontWeight: "bold" }} onClick={() => setShowAllRows(!showAllRows)}
                //  className="py-0 text-left pl-8">
                // {/* // <button */}
                // {/* //   onClick={() => setShowAllRows(!showAllRows)}
                // //   className="py-0 text-left pl-8"
                // // > */}
                //   {showAllRows ? (
                //     <div className="flex " style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
                   
                //     onMouseEnter={(e) => e.target.style.color = '#ff7847'}
                //     onMouseLeave={(e) => e.target.style.color = ''}>
                //       Show Less 
                //       <TiArrowSortedUp/>
                    
                //     </div>
                //   ) : (
                //     <div className="flex " style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
                   
                //     onMouseEnter={(e) => e.target.style.color = '#ff7847'}
                //     onMouseLeave={(e) => e.target.style.color = ''}>
                //       View More
                //       <TiArrowSortedDown />
                //       {/* <GoChevronDown/> */}
                     
                //     </div>
                //   )}
                // {/* </button> */}</caption>
        //       )}
        //     {/* </caption> */}
        //     <TableHead>
        //       <TableRow>
        //         <StyledTableCell>Issuer Company</StyledTableCell>
        //         <StyledTableCell align="right">Open Date</StyledTableCell>
        //         <StyledTableCell align="right">Close Date</StyledTableCell>
        //         <StyledTableCell align="right">Listing Date</StyledTableCell>

        //         <StyledTableCell align="right">
        //           Issue Price (Rs)
        //         </StyledTableCell>
        //         <StyledTableCell align="right">
        //           Issue Size (Rs Cr.)
        //         </StyledTableCell>
        //         <StyledTableCell align="right">Lot Size</StyledTableCell>
        //         <StyledTableCell align="right">Exchange</StyledTableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {filteredRows.slice(0, visibleRows).map((row, index) => (
        //         <StyledTableRow key={index}>
                  
        //           <StyledTableCell component="th" scope="row">
        //             <Link to={`/ipo/${row[1]}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        //                <div style={{ display: "flex", alignItems: "center" }}>
        //                 <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px" ,width:"50px"}} />
        //                 {row[1]}
        //             </div>
        //             </Link>
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[3])} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[4])} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {formatDate(row[5])}
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[6]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[7]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[8]} 
        //           </StyledTableCell>
        //           <StyledTableCell align="right">
        //             {row[9]} 
        //           </StyledTableCell>
        //         </StyledTableRow>
        //       ))}
        //     </TableBody>
        //   </Table>
        // </TableContainer>
         ) : (
          <p style={{ textAlign: 'center'}}>No Recently Listed {ipoType} available.</p>
        )}
     </div>

     <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
      <br/>
      </div>


      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{marginTop:"5px", width: "92%",maxWidth:"1200px",minWidth:"100px",height:"auto"}}><FaqComponent type="Recently Listed IPO" /></div></div>
     
    </>
  );
}
  export default RecentlyListedIPOs;