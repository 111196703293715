import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';
import '../Footer.css';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'));

  const getWidth = () => {
    if (isMobile) return '40%';
    if (isLaptop) return '15%';
    return '15%'; // Default width for other screen sizes
  };
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        {/* <h3>Company<span>logo</span></h3> */}<p className="footer-company-name" style={{fontSize:"15px",color:"#ccc",marginBottom:"30px",width:"100%"}}><strong>Disclaimer: </strong>The financial information provided in this app is for educational and informational purposes only. It should not be considered as advice to buy or sell securities, invest in IPOs, or as a guide for making any investment decisions. We are not SEBI-registered analysts. Before making any investment decisions, users should consult a qualified financial advisor. The information in the app reflects data available at the time of publication and market perceptions. By using this app, you agree to our terms and conditions.</p>
        <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            // sx={{
            //   mr: 2,
            //   display: { xs: 'none', lg: 'flex' },
            //   fontFamily: 'monospace',
            //   fontWeight: 700,
            //   letterSpacing: '.3rem',
            //   color: 'inherit',
            //   textDecoration: 'none',
            // }}
          >
            <img src='/orange technology data sync logo (1).png' alt='IPO TANK Logo'   style={{ width: getWidth() }}   />
          </Typography>
        <p className="footer-links">
          <Link to="/" className="link-1">Home</Link>
          <Link to="/current-ipo">Current IPO</Link>
          <Link to="/upcoming-ipo">Upcoming IPO</Link>
          <Link to="/recently-listed-ipo">Recently Listed IPO</Link>
          <Link to="/closed-ipo">Closed IPO</Link>
          <Link to="/ipo-news">News</Link>
          <Link to="/ipo-calendar">IPO Calendar</Link>
          <Link to="/buyback-calendar">Buyback Calendar</Link>
          <Link to="/rights-issue-calendar">Rights Issues Calendar</Link>
          <Link to="/ipo-gmp">GMP</Link>
          <Link to="/ipo-performance">Performance</Link>
          <Link to="/brokers">Brokers</Link>
          <Link to="/buybacks">Buyback</Link>
          <Link to="/rights-issues">Rights Issues</Link>
          <Link to="/AboutUs">About Us</Link>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
          <Link to="/terms-conditions">Terms & Conditions</Link>
          <Link to="/support-faqs">Support & FAQs</Link>
          {/* <Link to="/DisclaimerAndPrivacy">Disclaimer And Privacy</Link> */}
          <Link to="/ContactUs">Contact Us</Link>
        </p>
        <p className="footer-company-name" style={{fontSize:"15px",color:"#ccc"}}>All Rights Reserved.</p>
        {/* <div className="footer-icons" style={{display:"flex",alignItems:"center",justifyContent:"center",justifyItems:"center"}}>
          <div>
        <a href="mailto:ipotank@gmail.com" style={{fontSize:"14px"}}><FaEnvelope/></a></div>
          <div>support@company.com</div>
        </div> */}
      </div>

      {/* <div className="footer-center" > */}
        {/* <div className="footer-icons" style={{display:"flex",justifyItems:"center"}}>
          <a href="#" style={{fontSize:"15px"}}><FaMapMarkerAlt/></a>
          <p><span>444 S. Cedros Ave</span> Solana Beach, California</p>
        </div>
        <div className="footer-icons"style={{display:"flex",justifyItems:"center",marginTop:"20px"}}>
        <a href="#" style={{fontSize:"15px"}}><FaPhone/></a>
          <p>+1.555.555.5555</p>
        </div> */}
        {/* <div className="footer-icons" style={{display:"flex",alignItems:"center"}}>
        <a href="mailto:ipotank@gmail.com" style={{fontSize:"14px"}}><FaEnvelope/></a>
          <p>support@company.com</p>
        </div> */}
      {/* </div> */}

      {/* <div className="footer-right">
        <p className="footer-company-about">
          <span>About the company</span>
          Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit, eu auctor lacus vehicula sit amet.
        </p>
        <div className="footer-icons">
          <a href="#"><FaFacebook/></a>
          <a href="#"><FaTwitter/></a>
          <a href="#"><FaLinkedin/></a>
          <a href="#"><FaGithub/></a>
        </div>
      </div> */}
    </footer>
  );
}

export default Footer;

