// // // import * as React from 'react';
// // // import * as ReactDOM from 'react-dom';
// // // import { ScheduleComponent, TimelineYear, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, Inject } from '@syncfusion/ej2-react-schedule';
// // // // import { resourceData } from './datasource';
// // import * as React from 'react';
// // import * as ReactDOM from 'react-dom';
// // import { ScheduleComponent, Agenda, Inject, ViewsDirective, ViewDirective } from '@syncfusion/ej2-react-schedule';
// // // import { appData } from './datasource';
// // import { Internationalization } from '@syncfusion/ej2-base';
// // import { useEffect } from 'react';
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-base/styles/material.css";
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-buttons/styles/material.css";
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-calendars/styles/material.css";
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-dropdowns/styles/material.css"; 
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-inputs/styles/material.css"; 
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-navigations/styles/material.css"; 
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-popups/styles/material.css";
// // // import "https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-schedule/styles/material.css"; 
// // // import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"; 

// // function IPOCalendar() {

// //   useEffect(() => {
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-base/styles/material.css');
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-buttons/styles/material.css');
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-calendars/styles/material.css');
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-dropdowns/styles/material.css');
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-inputs/styles/material.css');
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-navigations/styles/material.css');
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-popups/styles/material.css');
// //     import('https://cdn.syncfusion.com/ej2/25.1.35/ej2-react-schedule/styles/material.css');
// //     import('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
// //   }, []);

// //   const appData = [
// //     {
// //         Id: 1,
// //         Subject: 'Explosion of Betelgeuse Star',
// //         StartTime: new Date(2018, 1, 11, 9, 30),
// //         EndTime: new Date(2018, 1, 11, 11, 0),
// //         CategoryColor: '#1aaa55'
// //     }, {
// //         Id: 2,
// //         Subject: 'Thule Air Crash Report',
// //         StartTime: new Date(2018, 1, 12, 12, 0),
// //         EndTime: new Date(2018, 1, 12, 14, 0),
// //         CategoryColor: '#357cd2'
// //     }, {
// //         Id: 3,
// //         Subject: 'Blue Moon Eclipse',
// //         StartTime: new Date(2018, 1, 13, 9, 30),
// //         EndTime: new Date(2018, 1, 13, 11, 0),
// //         CategoryColor: '#7fa900'
// //     }, {
// //         Id: 4,
// //         Subject: 'Meteor Showers in 2018',
// //         StartTime: new Date(2018, 1, 14, 13, 0),
// //         EndTime: new Date(2018, 1, 14, 14, 30),
// //         CategoryColor: '#ea7a57'
// //     }, {
// //         Id: 5,
// //         Subject: 'Milky Way as Melting pot',
// //         StartTime: new Date(2018, 1, 15, 12, 0),
// //         EndTime: new Date(2018, 1, 15, 14, 0),
// //         CategoryColor: '#00bdae'
// //     }, {
// //         Id: 6,
// //         Subject: 'Mysteries of Bermuda Triangle',
// //         StartTime: new Date(2018, 1, 15, 9, 30),
// //         EndTime: new Date(2018, 1, 15, 11, 0),
// //         CategoryColor: '#f57f17'
// //     }, {
// //         Id: 7,
// //         Subject: 'Glaciers and Snowflakes',
// //         StartTime: new Date(2018, 1, 16, 11, 0),
// //         EndTime: new Date(2018, 1, 16, 12, 30),
// //         CategoryColor: '#1aaa55'
// //     }, {
// //         Id: 8,
// //         Subject: 'Life on Mars',
// //         StartTime: new Date(2018, 1, 17, 9, 0),
// //         EndTime: new Date(2018, 1, 17, 10, 0),
// //         CategoryColor: '#357cd2'
// //     }, {
// //         Id: 9,
// //         Subject: 'Alien Civilization',
// //         StartTime: new Date(2018, 1, 19, 11, 0),
// //         EndTime: new Date(2018, 1, 19, 13, 0),
// //         CategoryColor: '#7fa900'
// //     }, {
// //         Id: 10,
// //         Subject: 'Wildlife Galleries',
// //         StartTime: new Date(2018, 1, 21, 11, 0),
// //         EndTime: new Date(2018, 1, 21, 13, 0),
// //         CategoryColor: '#ea7a57'
// //     }, {
// //         Id: 11,
// //         Subject: 'Best Photography 2018',
// //         StartTime: new Date(2018, 1, 22, 9, 30),
// //         EndTime: new Date(2018, 1, 22, 11, 0),
// //         CategoryColor: '#00bdae'
// //     }, {
// //         Id: 12,
// //         Subject: 'Smarter Puppies',
// //         StartTime: new Date(2018, 1, 9, 10, 0),
// //         EndTime: new Date(2018, 1, 9, 11, 30),
// //         CategoryColor: '#f57f17'
// //     }, {
// //         Id: 13,
// //         Subject: 'Myths of Andromeda Galaxy',
// //         StartTime: new Date(2018, 1, 7, 10, 30),
// //         EndTime: new Date(2018, 1, 7, 12, 30),
// //         CategoryColor: '#1aaa55'
// //     }, {
// //         Id: 14,
// //         Subject: 'Aliens vs Humans',
// //         StartTime: new Date(2018, 1, 5, 10, 0),
// //         EndTime: new Date(2018, 1, 5, 11, 30),
// //         CategoryColor: '#357cd2'
// //     }, {
// //         Id: 15,
// //         Subject: 'Facts of Humming Birds',
// //         StartTime: new Date(2018, 1, 20, 9, 30),
// //         EndTime: new Date(2018, 1, 20, 11, 0),
// //         CategoryColor: '#7fa900'
// //     }, {
// //         Id: 16,
// //         Subject: 'Sky Gazers',
// //         StartTime: new Date(2018, 1, 23, 11, 0),
// //         EndTime: new Date(2018, 1, 23, 13, 0),
// //         CategoryColor: '#ea7a57'
// //     }, {
// //         Id: 17,
// //         Subject: 'The Cycle of Seasons',
// //         StartTime: new Date(2018, 1, 12, 5, 30),
// //         EndTime: new Date(2018, 1, 12, 7, 30),
// //         CategoryColor: '#00bdae'
// //     }, {
// //         Id: 18,
// //         Subject: 'Space Galaxies and Planets',
// //         StartTime: new Date(2018, 1, 12, 17, 0),
// //         EndTime: new Date(2018, 1, 12, 18, 30),
// //         CategoryColor: '#f57f17'
// //     }, {
// //         Id: 19,
// //         Subject: 'Lifecycle of Bumblebee',
// //         StartTime: new Date(2018, 1, 15, 6, 0),
// //         EndTime: new Date(2018, 1, 15, 7, 30),
// //         CategoryColor: '#7fa900'
// //     }, {
// //         Id: 20,
// //         Subject: 'Sky Gazers',
// //         StartTime: new Date(2018, 1, 15, 16, 0),
// //         EndTime: new Date(2018, 1, 15, 18, 0),
// //         CategoryColor: '#ea7a57'
// //     }
// // ];
// // const eventSettings = { dataSource: appData };

// // const instance = new Internationalization();
// // const getTimeString = (value) => {
// //   return instance.formatDate(value, { skeleton: 'hm' });
// // }
// // const eventTemplate = (props) => {
// //   return (<div className="template-wrap">
// //     <div className="subject">{props.Subject}</div>
// //     <div className="time">
// //       Time: {getTimeString(props.StartTime)} - {getTimeString(props.EndTime)}</div>
// //   </div>);
// // }
// // return (
// // <ScheduleComponent style={{marginTop:"500px"}} width='100%' height='550px' agendaDaysCount={3} selectedDate={new Date(2018, 1, 15)} eventSettings={eventSettings}>
// // <ViewsDirective>
// //   <ViewDirective option='Agenda' eventTemplate={eventTemplate} allowVirtualScrolling={false} />
// // </ViewsDirective>
// // <Inject services={[Agenda]} />
// // </ScheduleComponent>

// // );
// // };


// // // const resourceData = [
// // //   {
// // //       Id: 1,
// // //       Subject: 'Explosion of Betelgeuse Star',
// // //       StartTime: new Date(2018, 3, 3, 9, 30),
// // //       EndTime: new Date(2018, 3, 3, 11, 0),
// // //       OwnerId: 1
// // //   },
// // //   {
// // //       Id: 2,
// // //       Subject: 'Thule Air Crash Report',
// // //       StartTime: new Date(2018, 3, 4, 12, 0),
// // //       EndTime: new Date(2018, 3, 4, 14, 0),
// // //       OwnerId: 2
// // //   },
// // //   {
// // //       Id: 3,
// // //       Subject: 'Blue Moon Eclipse',
// // //       StartTime: new Date(2018, 3, 5, 9, 30),
// // //       EndTime: new Date(2018, 3, 5, 11, 0),
// // //       OwnerId: 3
// // //   },
// // //   // Other data...
// // // ];
  
// // //   const eventSettings = { dataSource: resourceData };
// // //   const group = { resources: ['Projects', 'Categories'] };

// // //   const ownerData = [
// // //     { OwnerText: 'Nancy', Id: 1, OwnerColor: '#ffaa00' },
// // //     { OwnerText: 'Steven', Id: 2, OwnerColor: '#f8a398' },
// // //     { OwnerText: 'Michael', Id: 3, OwnerColor: '#7499e1' }
// // //   ];
// // //   return (<ScheduleComponent width='100%' height='550px' selectedDate={new Date(2018, 3, 1)} eventSettings={eventSettings}>
// // //     <ViewsDirective>
// // //       <ViewDirective option='TimelineYear' displayName='Horizontal Timeline Year' isSelected={true} />
// // //       <ViewDirective option='TimelineYear' displayName='Vertical Timeline Year' orientation='Vertical' group={group} />
// // //     </ViewsDirective>
// // //     <ResourcesDirective>
// // //       <ResourceDirective field='OwnerId' title='Owner' name='Owners' allowMultiple={true} dataSource={ownerData} textField='OwnerText' idField='Id' colorField='OwnerColor'>
// // //       </ResourceDirective>
// // //     </ResourcesDirective>
// // //     <Inject services={[TimelineYear]} />
// // //   </ScheduleComponent>);
// // // }
// // // ;
// // // ReactDOM.render(<IPOCalendar />, document.getElementById('schedule'));

// //   export default IPOCalendar;

// import React, { useState } from 'react';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import moment from 'moment';
// import Modal from 'react-modal';
// import { Link } from 'react-router-dom';
// import { SlArrowRight } from "react-icons/sl";

// const momentLocalizerInstance = momentLocalizer(moment);


// function IPOCalendar({allIPOs}) {
//   console.log(allIPOs)
//   const events = [
//     { title: 'IPO Open', start: new Date('2024-05-01'), end: new Date('2024-05-01'), type: 'open', company: 'Company A' },
//     { title: 'IPO Closed', start: new Date('2024-05-15'), end: new Date('2024-05-15'), type: 'closed', company: 'Company B' },
//     { title: 'IPO Listed', start: new Date('2024-06-01'), end: new Date('2024-06-01'), type: 'listed', company: 'Company C' },
//     // Add more IPO events as needed
//   ];
  
//   const companyInfo = {
//     'Company A': { name: 'Company A', description: 'Description of Company A', color: '#ff0000' }, // Red color for Company A
//     'Company B': { name: 'Company B', description: 'Description of Company B', color: '#00ff00' }, // Green color for Company B
//     'Company C': { name: 'Company C', description: 'Description of Company C', color: '#0000ff' }, // Blue color for Company C
//     // Add more company information as needed
//   };

//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [selectedCompany, setSelectedCompany] = useState(null);

//   const openModal = (company) => {
//     setSelectedCompany(company);
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   return (
//     <div>

// <div style={{backgroundImage: "url('../istockphoto-1044295184-612x612.jpg')",backgroundSize: "cover", backgroundRepeat: "no-repeat", padding:"70px 0"}}>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//         }}
//       >
//            <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
//     <div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
//         <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
//         onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
//           <p>
//             Home
//           </p>
//         </Link>
//         <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
//         <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
//         onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
//           <p>IPO</p>
//         </Link>
//         <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
//         <Link to={`/ipo-calendar`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
//         onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
//           <p>Calendar</p>
//         </Link>
//       </div>
//       <br/>
    
//       <h1 style={{marginTop:"-20px"}}>IPO Calendar</h1>
//       <h3 style={{marginTop:"30px"}}>The financial markets are on the move</h3>
//       <label style={{marginTop:"-10px"}}>Invest in companies listing on the Indian exchanges with an IPO</label>
//       </div>
  
//       </div>



//       </div>
//       <br/><br/>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//         }}
//       >
//            <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '30px' }}>
//       {/* <h1>IPO Calendar</h1> */}
//       <Calendar
//         localizer={momentLocalizerInstance}
//         events={events}
//         startAccessor="start"
//         endAccessor="end"
//         style={{ height: 500 }}
//         eventPropGetter={(event, start, end, isSelected) => ({
//           style: {
//             backgroundColor: companyInfo[event.company].color, // Set background color based on company
//           },
//         })}
//         onSelectEvent={(event) => openModal(companyInfo[event.company])}
//       />
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Company Information"
//         // backgroundSize={100}
//         // style={{ backgroundColor: 'white', maxWidth: '200px', maxHeight: '400px' }}
//       >
//         {selectedCompany && (
//           <div style={{ backgroundColor: 'white', maxWidth: '200px', maxHeight: '400px' }}>
//             <h2>{selectedCompany.name}</h2>
//             <p>{selectedCompany.description}</p>
//             <button onClick={closeModal} >Close</button>
//           </div>
//         )}
//       </Modal>
//       </div> </div>

   
//     </div>
//   );
// }


// export default IPOCalendar;

import React, { useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import Modal from "react-modal"; // Import the Modal component
import "../Calendar.css"; // Your custom styles
import { Helmet } from 'react-helmet';

function IPOCalendar({ allIPOs }) {
  // console.log(allIPOs)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setdata] = useState([]);

  // Memoize events
  const events = useMemo(() => {
    let events = [];
    allIPOs && allIPOs?.forEach(item => {
      events.push({
        date: new Date(item.OpenDate),
        title: item.IssuerCompany,
        type: "Open",
        color: "pink"
      });
      events.push({
        date: new Date(item.CloseDate),
        title: item.IssuerCompany,
        type: "Close",
        color: "orange"
      });
      events.push({
        date: new Date(item.ListingDate),
        title: item.IssuerCompany,
        type: "Listing",
        color: "#e4e753"
      });
    });
    return events;
  }, [allIPOs]);

  // Set default data for selected date
  useEffect(() => {
    const currentDateEvents = events.filter(
      (event) => event.date.toDateString() === selectedDate.toDateString()
    );
    setdata(currentDateEvents);
  }, [selectedDate, events]);
 

console.log(events)
  
 
const [isOpen, setIsOpen] = useState(false);

  const onChange = (date) => {
    setSelectedDate(date);
  };

  const getEventsForDate = (date) => {
    return events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    );
  };


  const customTileContent = ({ date,view }) => {
    const eventsForDay = getEventsForDate(new Date(date));
  
    let eventDots;
    if (eventsForDay.length === 0) {
      // console.log(eventsForDay)
      eventDots = null;
    } else {
      const shownEvents = eventsForDay.slice(0,2); // Show only the first two events
      const hiddenEventsCount = eventsForDay.length - shownEvents.length;
      eventDots = (
        <div className="event-dots-container">
          {shownEvents.map((event, index) => (
            <div
              key={index}
              className="event-dot"
              style={{ backgroundColor: event.color }}
              title={event.title}
            ></div>
          ))}
          {hiddenEventsCount > 0 && (
            <div className="event-dot-plus">{`+${hiddenEventsCount}`}</div>
          )}
        </div>
      );
    }
  
    return (
      <div className="custom-tile">
        {eventDots}
      </div>
    );
  };
  
  

  const handleDateClick = (date) => {
    setSelectedDate(date);
   
     setdata(events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    ))
  
    setIsOpen(true);
  };
  console.log(data)

  return (
    <> 
  <Helmet>
  <title>IPO Calendar - IPO schedule, important dates and updates at IPOtank</title>
  <meta name="description" content="Get the latest IPO schedule, updates and important dates at IPO tank. Explore a detailed IPO calendar featuring upcoming, current, closed, and recently listed IPOs. Access information on lot sizes, prices, subscriptions, and allotment statuses. Stay informed with IPOtank's comprehensive IPO schedule and updates." />
  <meta name="keywords" content="IPO, IPO schedule, IPO calendar, upcoming IPOs, current IPOs, closed IPOs, recently listed IPOs, IPO dates, IPO lot sizes, IPO prices, IPO subscriptions, IPO allotment, stock market, investments, IPOtank, IPO tank" />

  <meta property="og:url" content="https://www.ipotank.com/ipo-calendar" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="IPO Calendar - IPO schedule, important dates and updates at IPOtank" />
    <meta property="og:description" content="Get the latest IPO schedule, updates and important dates at IPO tank. Explore a detailed IPO calendar featuring upcoming, current, closed, and recently listed IPOs. Access information on lot sizes, prices, subscriptions, and allotment statuses. Stay informed with IPOtank's comprehensive IPO schedule and updates." />

</Helmet>

    <div>
  
      <div style={{ backgroundImage: "url('../best8.jfif')", backgroundSize: "cover", backgroundRepeat: "no-repeat", padding: "10px 0 10px 0" }}>
      
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
            <div style={{ textAlign: 'left', display: "flex", alignItems: "left", gap: "15px", color: "#666", paddingTop: "25px" }} >
              <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }} >
                <p>Home</p>
              </Link>
              <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
              <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
                <p>IPO</p>
              </Link>
              <p style={{ color: "black", fontWeight: "bold" }}><SlArrowRight style={{ height: "12px", width: "12px" }} /></p>
              <Link to={`/ipo-calendar`} onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
                <p>Calendar</p>
              </Link>
            </div>
            <br />
            {/* Title and Description */}
            <h1 style={{ marginTop: "-20px" }}>IPO Calendar</h1>
            {/* <h3 style={{ marginTop: "30px" }}>The financial markets are on the move</h3> */}
            <p style={{ marginTop: "-10px", textAlign: "justify",fontSize:"15px"}}>IPO calendar with details on upcoming, current, closed, and recently listed IPOs at IPOtank. Stay informed about important dates, lot sizes, prices, subscriptions, and allotment statuses.</p>
          </div>
        </div>
      </div>
     
      {/* Calendar Section */}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ width: '90%', maxWidth: '1200px', margin: '40px 0' }}>
      <div className="event-calendar">
      <div className="calendar-container" >
        <Calendar
          onChange={onChange}
          value={selectedDate}
          tileContent={customTileContent}
          onClickDay={handleDateClick}
          className="react-calendar"
        />
   
    
        
      </div>
      <div
  isOpen={isOpen}
  onRequestClose={() => setIsOpen(false)}
  className="modal-content" // Apply modal styles
>
  {/* Modal content */}
  
  <h4 style={{color:"black",borderBottom:"1px solid rgb(221, 220, 221)",paddingBottom:"10px",textAlign:"left"}}>IPO on {selectedDate.toDateString()}:</h4>
  <div style={{display:"flex",flexDirection:"row",alignContent:"center",gap:"10px",flexWrap:"wrap",marginTop:"-20px"}}>
  <div style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
  <p className="dot"
  style={{ backgroundColor: "pink"}}></p>
  <p
              // className="dot"
              style={{ marginLeft:"5px",fontSize:"13px",color:"#666" }}
  >Open Day</p></div>
 <div style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
  <p className="dot"
  style={{ backgroundColor: "orange"}}></p>
  <p
              // className="dot"
              style={{ marginLeft:"5px",fontSize:"13px",color:"#666" }}
  >Close Day</p></div>
  <div style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
  <p className="dot"
  style={{ backgroundColor: "#e4e753"}}></p>
  <p
              // className="dot"
              style={{ marginLeft:"5px",fontSize:"13px",color:"#666" }}
  >Listing Day</p></div></div>
  <div className="scrollable-container" style={{  height: "100%", width: "100%" }}>
<div style={{display:"flex",flexDirection:"column",gap:"10px",maxWidth:"500px",width:"100%"}}>
  {data && data.length > 0  ? (<>{data.map((event) => (
    <div key={event.title}>
     {/* <div> */}
     <Link to={`/ipo/${encodeURIComponent(event.title.toLowerCase().replace(/ /g, '-'))}`} style={{textDecoration:"none"}}>
        <div className="event-title" style={{fontSize:"14px",height:"auto",borderLeft:`10px solid ${event.color}`,borderTop:`2px solid ${event.color}`,borderRight:`2px solid ${event.color}`,borderBottom:`2px solid ${event.color}`,padding:"10px 10px",color:"#555",borderRadius:"8px", cursor: "pointer",  transition: "box-shadow 0.3s",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", textAlign:"left",// Initial box shadow
            }}
            onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.4)"}
            onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>{event.title}<div style={{fontSize:"13px",color:"black",marginTop:"5px",fontWeight:"lighter"}}>{event.type}</div></div>
    </Link>
        {/* Add more event details here if needed */}
        </div>
    // </div>
  ))}</>):(<p style={{fontSize:"13px"}}>On this date, the opening, closing and listing dates for the IPO are not available.</p>)}
 
  </div></div>
  {/* <button className="modal-close-btn" onClick={() => setIsOpen(false)} style={{marginTop:"15px",background:"linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%)",color:"white" , cursor: "pointer",  transition: "box-shadow 0.3s",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)", // Initial box shadow
            }}
            onMouseOver={(e) => e.currentTarget.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.4)"}
            onMouseOut={(e) => e.currentTarget.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 0.5)"}>Close</button> */}
</div>
    </div>
    </div>
    </div>
    </div> </>
  );
}

export default IPOCalendar;