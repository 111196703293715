import { BorderBottom } from '@mui/icons-material';
import React from 'react';
import { PiTextAlignJustify } from 'react-icons/pi';
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  const styles = {
    container: {
    marginTop:"100px",
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      padding: '20px',
      color: '#333',
      fontSize:"15px",
      
    },
    header: {
      fontSize: '2em',
      fontWeight: 'bold',
      marginBottom: '10px',
      paddingBottom: '10px',
      borderBottom:"1px solid #ccc"
    },
    subHeader: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    section: {
      marginBottom: '20px',
    },
    list: {
      paddingLeft: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
    contact: {
      marginTop: '30px',
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    emailLink: {
      color: '#007BFF',
      textDecoration: 'none',
    },
  };

  return (
    <>
    <Helmet>
    <title>About IPO Tank - Track current, upcoming, recently listed, closed IPO, and its GMP along with company's performance</title>
    <meta name="description" content="Complete IPO information at IPO Tank. A helpful guide for beginners on which IPO to invest in, with all the details you need." />

    <meta name="keywords" content="IPO, Initial Public Offerings, stock market, investments, IPO news, IPO updates, IPO trends, IPO investing, current IPOs, upcoming IPOs, IPO listings, closed IPOs, IPOtank, IPO tank" />
    <meta property="og:url" content="https://www.ipotank.com/AboutUs" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="About IPO Tank - Track current, upcoming, recently listed, closed IPO, and its GMP along with company's performance" />
    <meta property="og:description" content="Complete IPO information at IPO Tank. A helpful guide for beginners on which IPO to invest in, with all the details you need."/>
  </Helmet>

    <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize:"14px",
      marginTop:"30px",
      textAlign:"justify",
    }}
  >
       <div style={{ width: '90%', maxWidth: '1200px', marginBottom: '10px' }}>
    {/* <div style={styles.container}> */}
      <div style={styles.header}>About IPO Tank</div>
      <div style={styles.section}>
        Welcome to IPO Tank, your comprehensive resource for all things related to Initial Public Offerings (IPOs). Whether you’re an investor looking to stay updated on the latest IPOs or someone new to the stock market seeking to understand the IPO process, IPO Tank is here to provide you with accurate, timely, and detailed information.
      </div>

      <div style={styles.subHeader}>Our Mission</div>
      <div style={styles.section}>
        At IPO Tank, our mission is to empower investors with the knowledge and tools they need to make informed investment decisions. We strive to provide a one-stop platform where you can find everything related to IPOs in a user-friendly and accessible format.
      </div>

      <div style={styles.subHeader}>What We Offer</div>
      <div style={styles.section}>
        <ul style={styles.list}>
          <li style={styles.listItem}><strong>Current IPO:</strong> Stay informed about the latest IPOs hitting the market.</li>
          <li style={styles.listItem}><strong>Upcoming IPO:</strong> Get a sneak peek at the companies preparing to go public.</li>
          <li style={styles.listItem}><strong>Closed IPO:</strong> Access information about IPOs that have recently closed.</li>
          <li style={styles.listItem}><strong>Recently Listed IPO:</strong> Track the performance of companies that have recently gone public.</li>
          <li style={styles.listItem}><strong>IPO News:</strong> Stay updated with the latest news and trends in the IPO market.</li>
          <li style={styles.listItem}><strong>Subscription Status:</strong> Get real-time updates on the subscription status of ongoing IPOs.</li>
          <li style={styles.listItem}><strong>Allotment Status:</strong> Check the allotment status of the IPOs you have subscribed to.</li>
          <li style={styles.listItem}><strong>IPO Calculator:</strong> Use our calculator to evaluate potential returns and make better investment decisions.</li>
          <li style={styles.listItem}><strong>IPO Calendar:</strong> Plan your investment strategy with our detailed IPO calendar.</li>
        </ul>
      </div>

      <div style={styles.subHeader}>Why Choose IPO Tank?</div>
      <div style={styles.section}>
        Our platform is designed with the user in mind. We prioritize accuracy, timeliness, and comprehensiveness, ensuring that you have all the information you need at your fingertips. Our team of experts is dedicated to analyzing and presenting data in a clear and concise manner, helping you navigate the complex world of IPOs with ease.
      </div>

      <div style={styles.contact}>
        Get in Touch
      </div>
      <div style={styles.section}>
        Have questions or need more information? Contact us at <a href="mailto:ipotank@gmail.com" style={styles.emailLink}>ipotank@gmail.com</a> or follow us on our social media channels to stay updated.
      </div>

      <div style={styles.section}>
        Thank you for choosing IPO Tank as your trusted source for IPO information. We look forward to helping you achieve your investment goals.
      </div>
</div>
    </div>
    </>
  );
};

export default AboutUs;
