import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useEffect } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { hostInfo , token } from '../server';
import { ListItemIcon, Popover, useTheme } from '@mui/material';
import { useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

// const pages = ['Home', 'Current IPO','Upcomming IPOs','Recently Listed IPO','Closed IPO','IPO News', 'IPO Calculator'];
const pages = [
      { name: 'Home', link: '/' },
      { name: 'Current IPO', link: '/current-ipo' },
      { name: 'Closed IPO', link: '/closed-ipo' },
      { name: 'Upcoming IPO', link: '/upcoming-ipo' },
      { name: 'Recently Listed IPO', link: '/recently-listed-ipo' },
      { name: 'IPO News', link: '/ipo-news' },
      { name: 'IPO Calendar', link: '/ipo-calendar' },
      { name: 'Buyback Calendar', link: '/buyback-calendar' },
      { name: 'Rights Issues Calendar', link: '/rights-issue-calendar' },
      { name: 'IPO GMP', link: '/ipo-gmp' },
      { name: 'IPO Performance', link: '/ipo-performance' },
      { name: 'Brokers', link: '/brokers' },
      { name: 'Buyback', link: '/buybacks' },
      { name: 'Rights Issues', link: '/rights-issues' },

      { name: 'About Us Legal', link: '/AboutUs' },
      { name: 'Contact Us Legal', link: '/ContactUs' },
      { name: 'Privacy Policy Legal', link: '/PrivacyPolicy' },
      { name: 'Terms & Conditions Legal', link: '/terms-conditions' },
      { name: 'Support & FAQs Legal', link: '/support-faqs' },
    ];
    
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#ffffff',
    color: '#333333',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  '& .MuiMenuItem-root': {
    fontSize: '13px',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '13px',
  padding: '10px 20px',
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
  },
}));

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [navbarVisible, setNavbarVisible] = React.useState(true);

  // const searchBoxRef = useRef(null);
  const searchBoxRefMobile = useRef(null);
  const searchBoxRefDesktop = useRef(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      const isNavbarVisible = window.scrollY < 100; // Adjust the threshold as needed
      setNavbarVisible(isNavbarVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '20ch',
        '&:focus': {
          width: '40ch',
        },
      },
    },
  }));
  const [isHovered, setHovered] = useState(false);
  // const [isHoveredshuffle, setHoveredshuffle] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [search, setSearch] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // console.log(searchQuery && searchQuery);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
// console.log(selectedIndex)
const theme = useTheme();


const [dataipo, setDataipo] = useState([]);
const [databuyback, setDatabuyback] = useState([]);
const [databroker, setDatabroker] = useState([]);
const [datarightsissue, setDatarightsissue] = useState([]);

// console.log(dataipo,databuyback,databroker,datarightsissue);

    // useEffect(() => {
    //     const fetchData = async () => {
    //     try {
    //         const response = await fetch(`${hostInfo}/search_ipo`, {
    //           method: 'GET', // Specify the HTTP method
    //           headers: {
    //             'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
    //             'Content-Type': 'application/json', // Set the Content-Type if necessary
    //           }
    //         });
    //         if (!response.ok) {
    //           throw new Error('Network response was not ok');
    //         }
           
    //         const data = await response.json();
    //         // console.log(data)

    //         setSearch(data);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    //     };

    //     fetchData();
    // }, []);



    // Fetch data from multiple APIs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response1, response2, response3, response4] = await Promise.all([
          fetch(`${hostInfo}/search_ipo`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          fetch(`${hostInfo}/search_buyback`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          fetch(`${hostInfo}/search_broker`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          fetch(`${hostInfo}/search_rights_issue`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
        ]);

        if (!response1.ok || !response2.ok || !response3.ok || !response4.ok) {
          throw new Error("Error fetching data");
        }

        const data1 = await response1.json();
        const data2 = await response2.json();
        const data3 = await response3.json();
        const data4 = await response4.json();

        setDataipo(data1);
        setDatabuyback(data2);
        setDatabroker(data3);
        setDatarightsissue(data4);
    

        // Combine data from all APIs
        const combinedData = [...data1, ...data2, ...data3, ...data4];
        setSearch(combinedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


    // Helper function to check if the suggestion exists in the data array-of-arrays
    // const findInData = (data,suggestions) => {
    //   return data.some((item) => item[0] && item[0].includes(suggestions));
    // };

    // Helper function to check if the suggestion exists in the data array-of-arrays
const findInData = (data,suggestions) => {
  return data.some((item) => item[0] && item[0] === suggestions);
};

    
    // // console.log(search)

    const handleClick = () => {
      const inputValue = searchQuery;
      if (suggestions.length > 0) {

    

    // Check if the suggestion exists in data1, data2, or data3
    const foundInData1 = findInData(dataipo,suggestions[0]);
    const foundInData2 = findInData(databuyback,suggestions[0]);
    const foundInData3 = findInData(databroker,suggestions[0]);
    const foundInData4 = findInData(datarightsissue,suggestions[0]);

    // Navigate based on the source of the suggestion
    if (foundInData1) {
      navigate(`/ipo/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } else if (foundInData2) {
      navigate(`/buyback/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } else if (foundInData3) {
      // console.log("may  jaaa-----------------",foundInData3,suggestions[0]);
      navigate(`/broker/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } else if (foundInData4){
      navigate(`/rights-issue/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } 
    // else{
    //   navigate(`/`);
    //   console.error('Suggestion not found in any data source');
    // }

        // navigate(`/ipo/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
        setSearchQuery("");
        setSuggestions([]);
      } 
      else {
        // navigate(`/`);
        setSearchQuery("");
        setSuggestions([]); // Clear suggestions after selection
        // setSearchQuery("");
        // setSelectedIndex(0); // Reset selected index

      }
    };

    const handleSearchChange = (event) => {
      const query = event.target.value.toLowerCase(); // Convert query to lowercase
      setSearchQuery(event.target.value);
    setSelectedSuggestion(null)
      const uniqueSuggestions = new Set();
    
      const filteredSuggestions = search
        .flatMap((product) => [product[0]]) // Convert product titles to lowercase
        .filter((value) => {
          return (
            !uniqueSuggestions.has(value) &&
            uniqueSuggestions.add(value)
          );
        })
        .filter((value) => {
          // // console.log(value.replace(/\s/g, ""));
          const words = query.split(" ");
          return words.every((word)=>
            value.replace(/\s/g, "").toLowerCase().includes(word)
  
          );
        });
        // .map((value) => {
        //   const foundCar = searchcar.find(
        //     (car) => car.Title.toLowerCase() === value.toLowerCase()
        //   );
        //   return foundCar ? foundCar.Title : value;
        //   // // console.log(foundCar);
        // });
  
        // .filter((value) => value.includes(query));
    
      setSuggestions(query.trim() !== "" ? filteredSuggestions : []);

      if (event.key === 'Enter' && suggestions.length > 0) {
        // If Enter is pressed and there are suggestions, select the first one
        setSearchQuery(suggestions[0]);
        setSuggestions([]);

          // Check if the suggestion exists in data1, data2, or data3
    const foundInData1 = findInData(dataipo,suggestions[0]);
    const foundInData2 = findInData(databuyback,suggestions[0]);
    const foundInData3 = findInData(databroker,suggestions[0]);
    const foundInData4 = findInData(datarightsissue,suggestions[0]);

    // Navigate based on the source of the suggestion
    if (foundInData1) {
      navigate(`/ipo/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } else if (foundInData2) {
      navigate(`/buyback/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } else if (foundInData3) {
      // console.log("may  jaaa-----------------",foundInData3,suggestions[0]);
      navigate(`/broker/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } else if (foundInData4){
      navigate(`/rights-issue/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
    } 
    // else{
    //   navigate(`/`);
    //   console.error('Suggestion not found in any data source');
    // }

        // navigate(`/ipo/${encodeURIComponent(suggestions[0]?.toLowerCase().replace(/ /g, '-'))}`);
        // setSearchQuery("");
      }
    };
    // console.log(suggestions)

  // const handleSuggestionClick = (suggestion) => {
  //   setSearchQuery(suggestion);
  //   setSuggestions([]);
  //   router.push(`/car/carinfo?title=${suggestion}`);
  //   setSearchQuery("");
  // };
 

  // const handleSuggestionClick = (suggestion) => {
  //   setSearchQuery(suggestion);
  //   setSelectedSuggestion(suggestion)
  //   setSuggestions([]);
  //   // Use window.location.href for navigation
  //   navigate(`/ipo/${encodeURIComponent(suggestion.toLowerCase().replace(/ /g, '-'))}`);
  //   setSearchQuery("");
  //   setSelectedIndex(0)
  // };
  const handleSuggestionClick = (suggestion) => {
    if (typeof suggestion === 'string') {
      setSearchQuery("");
      setSelectedSuggestion(suggestion);
      setSuggestions([]);
      setSelectedIndex(0);


  // Check if the suggestion exists in data1, data2, or data3
  const foundInData1 = findInData(dataipo,suggestion);
  const foundInData2 = findInData(databuyback,suggestion);
  const foundInData3 = findInData(databroker,suggestion);
  const foundInData4 = findInData(datarightsissue,suggestion);

  // Navigate based on the source of the suggestion
  if (foundInData1) {
    navigate(`/ipo/${encodeURIComponent(suggestion?.toLowerCase().replace(/ /g, '-'))}`);
  } else if (foundInData2) {
    navigate(`/buyback/${encodeURIComponent(suggestion?.toLowerCase().replace(/ /g, '-'))}`);
  } else if (foundInData3) {
    // console.log("may  jaaa-----------------",foundInData3,suggestion);
    navigate(`/broker/${encodeURIComponent(suggestion?.toLowerCase().replace(/ /g, '-'))}`);
  } else if (foundInData4){
    navigate(`/rights-issue/${encodeURIComponent(suggestion?.toLowerCase().replace(/ /g, '-'))}`);
  } 
  // else{
  //   navigate(`/`);
  //   console.error('Suggestion not found in any data source');
  // }



      // Use navigate for navigation
      // navigate(`/ipo/${encodeURIComponent(suggestion?.toLowerCase().replace(/ /g, '-'))}`);

    } else {
      console.error('Suggestion is not a string:', suggestion);
    }
  };
  
  
  // console.log(searchQuery);
  
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && suggestions.length > 0) {
      setSearchQuery(suggestions[selectedIndex]);
      handleSuggestionClick(suggestions[selectedIndex]);
      setSuggestions([]); // Clear suggestions after selection
      setSelectedIndex(0); // Reset selected index
    } else if (event.key === "ArrowDown" && suggestions.length > 0) {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (event.key === "ArrowUp" && suggestions.length > 0) {
      setSelectedIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
    }
  };
  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault(); // Prevent default behavior of the Enter key
  
  //     if (selectedIndex >= 0 && selectedIndex < suggestions.length) {
  //       // If there is a selected suggestion, perform the search for that suggestion
  //       setSearchQuery(suggestions[selectedIndex]);
  //       handleSuggestionClick(suggestions[selectedIndex]);
  //       setSuggestions([]); // Clear suggestions after selection
  //       setSelectedIndex(0); // Reset selected index
  //     } else {
  //       // If there is no selected suggestion, perform the regular search
  //       handleClick();
  //     }
  //   } else if (event.key === "ArrowDown" && suggestions.length > 0) {
  //     setSelectedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
  //   } else if (event.key === "ArrowUp" && suggestions.length > 0) {
  //     setSelectedIndex((prevIndex) =>
  //       prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
  //     );
  //   }
  // };


  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);

const handleCalendarMenuOpen = (event) => {
  setCalendarAnchorEl(event.currentTarget);
};

const handleCalendarMenuClose = () => {
  setCalendarAnchorEl(null);
};


const [legalAnchorEl, setLegalAnchorEl] = useState(null);

const handleLegalMenuOpen = (event) => {
  setLegalAnchorEl(event.currentTarget);
};

const handleLegalMenuClose = () => {
  setLegalAnchorEl(null);
};


  // const handleMenuToggle = (event) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

    

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };



  // const ipoPages = pages.filter(page => page.name.toLowerCase().endsWith('ipo'));
  // const otherPages = pages.filter(page => !page.name.toLowerCase().endsWith('ipo'));

  // const homePage = otherPages.find(page => page.name.toLowerCase() === 'home');
  // const remainingPages = otherPages.filter(page => page.name.toLowerCase() !== 'home');

const ipoPages = pages.filter(page => page.name.toLowerCase().endsWith('ipo'));
const calendarPages = pages.filter(page => page.name.toLowerCase().endsWith('calendar'));
const legalPages = pages.filter(page => page.name.toLowerCase().endsWith('legal'));
const otherPages = pages.filter(page => !page.name.toLowerCase().endsWith('ipo') && !page.name.toLowerCase().endsWith('calendar') && !page.name.toLowerCase().endsWith('legal'));
const homePage = otherPages.find(page => page.name.toLowerCase() === 'home');
const remainingPages = otherPages.filter(page => page.name.toLowerCase() !== 'home');


  const handleClickOutside = (event) => {
    if (
      (searchBoxRefMobile.current && !searchBoxRefMobile.current.contains(event.target)) &&
      (searchBoxRefDesktop.current && !searchBoxRefDesktop.current.contains(event.target))
    ) {
      setSearchQuery('');
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
  //       setSearchQuery('');
  //       setSuggestions([]);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [searchBoxRef]);


  // const [anchorElNav, setAnchorElNav] = useState(null);


  // const [isIPOSubMenuOpen, setIsIPOSubMenuOpen] = useState(false);
  const [isIPOSubMenuOpen, setIPOSubMenuOpen] = useState(false);
  const [isCalendarSubMenuOpen, setCalendarSubMenuOpen] = useState(false);
  const [isLegalSubMenuOpen, setLegalSubMenuOpen] = useState(false);

  const handleOpenNavMenu1 = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu1 = () => {
    setAnchorElNav(null);
    setIPOSubMenuOpen(false);
    setCalendarSubMenuOpen(false);
    setLegalSubMenuOpen(false);
  };

  // const handleToggleIPOSubMenu = () => {
  //   setIsIPOSubMenuOpen((prev) => !prev);
  // };



// const handleToggleIPOSubMenu = () => {
//   setIPOSubMenuOpen((prev) => !prev);
// };

// const handleToggleCalendarSubMenu = () => {
//   setCalendarSubMenuOpen((prev) => !prev);
// };

// const handleToggleLegalSubMenu = () => {
//   setLegalSubMenuOpen((prev) => !prev);
// };



const handleToggleSubMenu = (menu) => {
  switch (menu) {
    case 'IPO':
      setIPOSubMenuOpen((prev) => !prev);
      break;
    case 'Calendar':
      setCalendarSubMenuOpen((prev) => !prev);
      break;
    case 'Legal':
      setLegalSubMenuOpen((prev) => !prev);
      break;
    default:
      break;
  }
};

  const pages1 = [
    { name: 'Home', link: '/' },
    { name: 'IPO', subPages: [
        { name: 'Current IPO', link: '/current-ipo' },
        { name: 'Closed IPO', link: '/closed-ipo' },
        { name: 'Upcoming IPO', link: '/upcoming-ipo' },
        { name: 'Recently Listed IPO', link: '/recently-listed-ipo' }
      ]
    },
    { name: 'IPO News', link: '/ipo-news' },
    { name: 'Calendar', subPages: [
      { name: 'IPO Calendar', link: '/ipo-calendar' },
      { name: 'Buyback Calendar', link: '/buyback-calendar' },
      { name: 'Rights Issues Calendar', link: '/rights-issue-calendar' },
    ]
  },
    // { name: 'IPO Calendar', link: '/ipo-calendar' },
    { name: 'IPO GMP', link: '/ipo-gmp' },
    { name: 'IPO Performance', link: '/ipo-performance' },
    { name: 'Brokers', link: '/brokers' },
    { name: 'Buyback', link: '/buybacks' },
    { name: 'Rights Issues', link: '/rights-issues' },
    { name: 'Legal', subPages: [
      { name: 'About Us', link: '/AboutUs' },
      { name: 'Contact Us', link: '/ContactUs' },
      { name: 'Privacy Policy', link: '/PrivacyPolicy' },
      { name: 'Terms & Conditions', link: '/terms-conditions' },
      { name: 'Support & FAQs', link: '/support-faqs' },
    ]
  },
  ];
  

  return (
      <AppBar position="fixed" style={{backgroundColor:"#666",border:"1px solid #666"}}>
      <Container maxWidth="xxl" >
        <Toolbar disableGutters>

        {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex'   ,  sm: 'flex' , lg:"none"  } }}>
  <IconButton
    size="large"
    aria-label="account of current user"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    onClick={handleOpenNavMenu1}
    color="inherit"
  >
    <MenuIcon />
  </IconButton>
  <Menu
    id="menu-appbar"
    anchorEl={anchorElNav}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    open={Boolean(anchorElNav)}
    onClose={handleCloseNavMenu1}
    sx={{
      display: { xs: 'block', md: 'block'   ,  sm: 'block' , lg:"none"}
    }}
  >
    {pages1.map((page) => (
      <div key={page.name}>
        {page.subPages ? (
          <>
            <MenuItem
              onClick={page.name === 'IPO' ? handleToggleIPOSubMenu : handleToggleCalendarSubMenu}
              style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
            >
              <Typography textAlign="center" style={{ fontSize: "14px" }}>{page.name}</Typography>
              <ListItemIcon style={{ marginRight: "-15px" }}>
                {page.name === 'IPO' ? (isIPOSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />) :
                  (isCalendarSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItemIcon>
            </MenuItem>

            {page.name === 'IPO' && isIPOSubMenuOpen && (
              <div style={{ fontSize: '0.5rem', margin: '0px 15px 0px 15px', border: '1px solid #ccc', borderRadius: '5px' }}>
                {page.subPages.map((subPage) => (
                  <MenuItem
                    key={subPage.name}
                    onClick={handleCloseNavMenu1}
                    component={Link}
                    to={subPage.link}
                    style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                  >
                    <Typography textAlign="center" style={{ fontSize: "14px" }}>{subPage.name}</Typography>
                  </MenuItem>
                ))}
              </div>
            )}

            {page.name === 'Calendar' && isCalendarSubMenuOpen && (
              <div style={{ fontSize: '0.5rem', margin: '0px 15px 0px 15px', border: '1px solid #ccc', borderRadius: '5px' }}>
                {page.subPages.map((subPage) => (
                  <MenuItem
                    key={subPage.name}
                    onClick={handleCloseNavMenu1}
                    component={Link}
                    to={subPage.link}
                    style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                  >
                    <Typography textAlign="center" style={{ fontSize: "14px" }}>{subPage.name}</Typography>
                  </MenuItem>
                ))}
              </div>
            )}
          </>
        ) : (
          <MenuItem
            key={page.name}
            onClick={handleCloseNavMenu1}
            component={Link}
            to={page.link}
            style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
          >
            <Typography textAlign="center" style={{ fontSize: "14px" }}>{page.name}</Typography>
          </MenuItem>
        )}
      </div>
    ))}
  </Menu>
        </Box> */}

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex'   ,  sm: 'flex' , lg:"none" } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu1}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu1}
        sx={{
          display: { xs: 'block', md: 'block'   ,  sm: 'block' , lg:"none" },
          // padding:"50px 0px"
        }}
      >
        <div style={{ width: "100%",padding:"10px 16px",backgroundColor:"#888",marginBottom:"10px" }}><img src='/orange technology data sync logo (1).png' alt='IPO TANK Logo' style={{ width: "100px"}} /></div>
        {pages1.map((page) => (
          <div key={page.name}>
            {page.subPages ? (
              <>
                <MenuItem
                  onClick={() => handleToggleSubMenu(page.name)}
                  style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                >
                  <Typography textAlign="center" style={{ fontSize: "14px" }}>{page.name}</Typography>
                  <ListItemIcon style={{ marginRight: "-15px" }}>
                    {page.name === 'IPO' && (isIPOSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                    {page.name === 'Calendar' && (isCalendarSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                    {page.name === 'Legal' && (isLegalSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                  </ListItemIcon>
                </MenuItem>

                {page.name === 'IPO' && isIPOSubMenuOpen && (
                  <div style={{ fontSize: '0.5rem', margin: '0px 15px 0px 15px', border: '1px solid #ccc', borderRadius: '5px' }}>
                    {page.subPages.map((subPage) => (
                      <MenuItem
                        key={subPage.name}
                        onClick={handleCloseNavMenu1}
                        component={Link}
                        to={subPage.link}
                        style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                      >
                        <Typography textAlign="center" style={{ fontSize: "14px" }}>{subPage.name}</Typography>
                      </MenuItem>
                    ))}
                  </div>
                )}

                {page.name === 'Calendar' && isCalendarSubMenuOpen && (
                  <div style={{ fontSize: '0.5rem', margin: '0px 15px 0px 15px', border: '1px solid #ccc', borderRadius: '5px' }}>
                    {page.subPages.map((subPage) => (
                      <MenuItem
                        key={subPage.name}
                        onClick={handleCloseNavMenu1}
                        component={Link}
                        to={subPage.link}
                        style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                      >
                        <Typography textAlign="center" style={{ fontSize: "14px" }}>{subPage.name}</Typography>
                      </MenuItem>
                    ))}
                  </div>
                )}

                {page.name === 'Legal' && isLegalSubMenuOpen && (
                  <div style={{ fontSize: '0.5rem', margin: '0px 15px 5px 15px', border: '1px solid #ccc', borderRadius: '5px' }}>
                    {page.subPages.map((subPage) => (
                      <MenuItem
                        key={subPage.name}
                        onClick={handleCloseNavMenu1}
                        component={Link}
                        to={subPage.link}
                        style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                      >
                        <Typography textAlign="center" style={{ fontSize: "14px" }}>{subPage.name}</Typography>
                      </MenuItem>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <MenuItem
                onClick={handleCloseNavMenu1}
                component={Link}
                to={page.link}
                style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
              >
                <Typography textAlign="center" style={{ fontSize: "14px" }}>{page.name}</Typography>
              </MenuItem>
            )}
          </div>
        ))}
      </Menu>
    </Box>

          {/* <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              maxWidth:"20%",
              // mr: 2,
              display: { lg: 'none', xs: 'flex', md: 'none'   ,  sm: 'none' },
              fontFamily: 'monospace',
              // fontWeight: 700,
              // letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src='/favicon.png' alt='IPO TANK Logo' style={{ width: "50%" }} />
          </Typography> */}

          {/* <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              // maxWidth:"10%",
              // mr: 2,
              display: { lg: 'none', xs: 'none', sm:'flex' },
              fontFamily: 'monospace',
              // fontWeight: 700,
              // letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src='/favicon.png' alt='IPO TANK Logo' style={{ width: "50px",height:"50px" }} />
          </Typography> */}

          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              // mr: 1,
              maxWidth: {lg: "10%", md: '20%' , sm: '20%'},
              display: { xs: 'none', lg: 'flex' , md: 'none'   ,  sm: 'none'},
              fontFamily: 'monospace',
              // fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src='/orange technology data sync logo (1).png' alt='IPO TANK Logo' style={{ width: "90%" }} />
          </Typography>

          {/* <Box
           ref={searchBoxRefMobile}
            sx={{
              position: 'relative',
              display: { lg: 'none', xs: 'flex' },
              alignItems: 'center',
              width: '100%',
              maxWidth: '400px',
              // marginRight: '5px',
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <input
              type="text"
              style={{
                width: '100%',
                maxWidth: '400px',
                padding: '0.5rem',
                paddingLeft: '1rem',
                fontSize: '0.875rem',
                color: '#1a202c',
                border: '1px solid #cbd5e0',
                outline: 'none',
                borderRadius: '0.375rem',
                backgroundColor: '#edf2f7',
              }}
              placeholder="Search...."
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              required
            />
            {searchQuery?.trim() !== '' && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(100% + 5px)',
                  fontSize: '13px',
                  width: '100%',
                  maxWidth: '400px',
                  backgroundColor: '#edf2f4',
                  border: '1px solid #d1d5db',
                  borderRadius: '0.375rem',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                  overflowY: 'auto',
                  maxHeight: '150px',
                  marginTop: '-3px',
                  listStyleType: 'none',
                  zIndex: '30',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#bfc3c9 #f3f4f6',
                }}
              >
                {suggestions.length > 0 ? (
                  suggestions.map((suggestion, index) => (
                    // <div key={suggestion}>
                      <li
                      key={suggestion}
                        style={{
                          outline: 'none',
                          textDecoration: 'none',
                          color: selectedIndex === index ? '#ff7847' : 'black',
                          padding: '0.2rem 1rem',
                          cursor: 'pointer',
                          marginBottom: '1px',
                          backgroundColor: selectedIndex === index ? '#edf2f7' : 'white',
                          transition: 'background-color 0.3s',
                        }}
                        // onClick={(e) => handleSuggestionClick(e, suggestion)}
                        onClick={() => handleSuggestionClick(suggestion)}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = '#edf2f7';
                          e.target.style.color = '#ff7847';
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = 'white';
                          e.target.style.color = 'black';
                        }}
                      >
                        {suggestion}
                      </li>
                    // </div>
                  ))
                ) : (
                  <Link to={`/`} style={{ textDecoration: 'none' }}>
                    <li
                      style={{
                        outline: 'none',
                        textDecoration: 'none',
                        color: selectedIndex ? '#ff7847' : 'black',
                        padding: '0.2rem 1rem',
                        cursor: 'pointer',
                        marginBottom: '1px',
                        backgroundColor: selectedIndex ? '#edf2f7' : 'white',
                        transition: 'background-color 0.3s',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#edf2f7';
                        e.target.style.color = '#ff7847';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = 'white';
                        e.target.style.color = 'black';
                      }}
                    >
                      IPO not available..
                    </li>
                  </Link>
                )}
              </Box>
            )}
            <button
              className="search-active"
              onClick={handleClick}
              style={{
                position: 'absolute',
                right: '0',
                fontSize: '18px',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                marginRight: '8px',
                outline: 'none',
                color: isHovered ? '#ff7847' : '#666',
                transition: 'color 0.3s',
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </Box> */}











          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu} component={Link} to={page.link} >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

{/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu1}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu1}
        sx={{
          display: { xs: 'block', md: 'none' }
        }}
        
      >
        {pages1.map((page) => (
          <div key={page.name}>
            {page.subPages ? (
              <>
                <MenuItem onClick={handleToggleIPOSubMenu} style={{display:"flex",justifyContent:"space-between",paddingTop:"0",paddingBottom:"0",margin:"0"}}>
                  <Typography textAlign="center" style={{fontSize:"14px"}}>{page.name}</Typography>
                  <ListItemIcon style={{marginRight:"-15px"}}>
                    {isIPOSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemIcon>
                </MenuItem>
                {isIPOSubMenuOpen && (
                <div  style={{  
                        fontSize: '0.5rem',
                        margin: '0px 15px 0px 15px',
                        // padding:"10px",
                        border: '1px solid #ccc',
                        borderRadius: '5px' }}>
                {isIPOSubMenuOpen && (
                  page.subPages.map((subPage) => (
                    <MenuItem
                      key={subPage.name}
                      onClick={handleCloseNavMenu1}
                      component={Link}
                      to={subPage.link}
                      style={{display:"flex",justifyContent:"space-between",paddingTop:"0",paddingBottom:"0",margin:"0"}}
                      // sx={{  pl: 0,
                      //   fontSize: '13px',
                      //   margin: '10px',
                      //   padding:"10px",
                      //   border: '1px solid #ccc',
                      //   borderRadius: '5px' }} // Add padding to indent the sub-menu items
                    >
                      <Typography textAlign="center" style={{fontSize:"14px"}}>{subPage.name}</Typography>
                    </MenuItem>
                  ))
                )}</div>)}
              </>
            ) : (
              <MenuItem
                key={page.name}
                onClick={handleCloseNavMenu1}
                component={Link}
                to={page.link}
                // style={{fontSize:"13px"}}
                style={{display:"flex",justifyContent:"space-between",paddingTop:"0",paddingBottom:"0",margin:"0"}}
              >
                <Typography textAlign="center" style={{fontSize:"14px"}}>{page.name}</Typography>
              </MenuItem>
            )}
          </div>
        ))}
      </Menu>
    </Box> */}


    {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
  <IconButton
    size="large"
    aria-label="account of current user"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    onClick={handleOpenNavMenu1}
    color="inherit"
  >
    <MenuIcon />
  </IconButton>
  <Menu
    id="menu-appbar"
    anchorEl={anchorElNav}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    open={Boolean(anchorElNav)}
    onClose={handleCloseNavMenu1}
    sx={{
      display: { xs: 'block', md: 'none' }
    }}
  >
    {pages1.map((page) => (
      <div key={page.name}>
        {page.subPages ? (
          <>
            <MenuItem
              onClick={page.name === 'IPO' ? handleToggleIPOSubMenu : handleToggleCalendarSubMenu}
              style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
            >
              <Typography textAlign="center" style={{ fontSize: "14px" }}>{page.name}</Typography>
              <ListItemIcon style={{ marginRight: "-15px" }}>
                {page.name === 'IPO' ? (isIPOSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />) :
                  (isCalendarSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItemIcon>
            </MenuItem>

            {page.name === 'IPO' && isIPOSubMenuOpen && (
              <div style={{ fontSize: '0.5rem', margin: '0px 15px 0px 15px', border: '1px solid #ccc', borderRadius: '5px' }}>
                {page.subPages.map((subPage) => (
                  <MenuItem
                    key={subPage.name}
                    onClick={handleCloseNavMenu1}
                    component={Link}
                    to={subPage.link}
                    style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                  >
                    <Typography textAlign="center" style={{ fontSize: "14px" }}>{subPage.name}</Typography>
                  </MenuItem>
                ))}
              </div>
            )}

            {page.name === 'Calendar' && isCalendarSubMenuOpen && (
              <div style={{ fontSize: '0.5rem', margin: '0px 15px 0px 15px', border: '1px solid #ccc', borderRadius: '5px' }}>
                {page.subPages.map((subPage) => (
                  <MenuItem
                    key={subPage.name}
                    onClick={handleCloseNavMenu1}
                    component={Link}
                    to={subPage.link}
                    style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
                  >
                    <Typography textAlign="center" style={{ fontSize: "14px" }}>{subPage.name}</Typography>
                  </MenuItem>
                ))}
              </div>
            )}
          </>
        ) : (
          <MenuItem
            key={page.name}
            onClick={handleCloseNavMenu1}
            component={Link}
            to={page.link}
            style={{ display: "flex", justifyContent: "space-between", paddingTop: "0", paddingBottom: "0", margin: "0" }}
          >
            <Typography textAlign="center" style={{ fontSize: "14px" }}>{page.name}</Typography>
          </MenuItem>
        )}
      </div>
    ))}
  </Menu>
</Box> */}


{/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu1}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu1}
      >
        {pages1.map((page) => (
          <div key={page.name}>
            {page.subPages ? (
              <>
                <StyledMenuItem onClick={handleToggleIPOSubMenu}>
                  <Typography textAlign="center">{page.name}</Typography>
                  <ListItemIcon>
                    {isIPOSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemIcon>
                </StyledMenuItem>
                {isIPOSubMenuOpen && (
                  page.subPages.map((subPage) => (
                    <StyledMenuItem
                      key={subPage.name}
                      onClick={handleCloseNavMenu1}
                      component={Link}
                      to={subPage.link}
                      sx={{ pl: 4 }} // Add padding to indent the sub-menu items
                    >
                      <Typography textAlign="center">{subPage.name}</Typography>
                    </StyledMenuItem>
                  ))
                )}
              </>
            ) : (
              <StyledMenuItem
                key={page.name}
                onClick={handleCloseNavMenu1}
                component={Link}
                to={page.link}
              >
                <Typography textAlign="center">{page.name}</Typography>
              </StyledMenuItem>
            )}
          </div>
        ))}
      </StyledMenu>
    </Box> */}

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } ,margin:"0px 20px"
        //   ,
        // position: 'relative', // Make the Box relative
      }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.link}
                sx={{ my: 2, color: 'white',display:"block",  whiteSpace: 'nowrap',textAlign:"center",
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: '0.775rem' // Set a smaller font size (adjust as needed)
                // maxWidth: '200px',
                // width: '100px',
                // fontSize: theme.typography.body1.fontSize, // Responsive font size based on theme
               }}
              >
                {page.name}
              </Button>
            ))}
          </Box> */}

<Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex',  sm: 'none', md:"none"  }, margin: "0px 20px" }}>
      {homePage && (
        <Button
          key={homePage.name}
          component={Link}
          to={homePage.link}
          sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
                textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem' }}
        >
          {homePage.name}
        </Button>
      )}
      {ipoPages.length > 0 && (
        <>
          <Button
            onMouseEnter={handleMenuOpen}
            sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
                  textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem', display: 'flex', alignItems: 'center' }}
          >
            IPO {anchorEl ? <IoIosArrowUp fontSize="small" style={{ display: "flex", alignItems: "center", marginLeft: "3px" }} /> : <IoIosArrowDown fontSize="small" style={{ display: "flex", alignItems: "center", marginLeft: "3px" }} />}
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              onMouseLeave: handleMenuClose,
            }}
          >
            <Box sx={{ px: 1, py: 1 }}>
              {ipoPages.map((page) => (
                <MenuItem
                  key={page.name}
                  component={Link}
                  to={page.link}
                  onClick={handleMenuClose}
                  sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {page.name}
                </MenuItem>
              ))}
            </Box>
          </Popover>
        </>
      )}
    
      {remainingPages.map((page) => (
        <Button
          key={page.name}
          component={Link}
          to={page.link}
          sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
                textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem' }}
        >
          {page.name}
        </Button>
      ))}

        {/* Calendar Menu */}
  {calendarPages.length > 0 && (
    <>
      <Button
        onMouseEnter={handleCalendarMenuOpen}
        sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
              textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem', display: 'flex', alignItems: 'center' }}
      >
        Calendar {calendarAnchorEl ? <IoIosArrowUp fontSize="small" style={{ display: "flex", alignItems: "center", marginLeft: "3px" }} /> : <IoIosArrowDown fontSize="small" style={{ display: "flex", alignItems: "center", marginLeft: "3px" }} />}
      </Button>
      <Popover
        open={Boolean(calendarAnchorEl)}
        anchorEl={calendarAnchorEl}
        onClose={handleCalendarMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          onMouseLeave: handleCalendarMenuClose,
        }}
      >
        <Box sx={{ px: 1, py: 1 }}>
          {calendarPages.map((page) => (
            <MenuItem
              key={page.name}
              component={Link}
              to={page.link}
              onClick={handleCalendarMenuClose}
              sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {page.name}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  )}


   {/* Legal Menu */}
   {legalPages.length > 0 && (
    <>
      <Button
        onMouseEnter={handleLegalMenuOpen}
        sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
              textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem', display: 'flex', alignItems: 'center' }}
      >
        Legal {legalAnchorEl ? <IoIosArrowUp fontSize="small" style={{ display: "flex", alignItems: "center", marginLeft: "3px" }} /> : <IoIosArrowDown fontSize="small" style={{ display: "flex", alignItems: "center", marginLeft: "3px" }} />}
      </Button>
      <Popover
        open={Boolean(legalAnchorEl)}
        anchorEl={legalAnchorEl}
        onClose={handleLegalMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          onMouseLeave: handleLegalMenuClose,
        }}
      >
        <Box sx={{ px: 1, py: 1 }}>
          {legalPages.map((page) => (
            <MenuItem
              key={page.name}
              component={Link}
              to={page.link}
              onClick={handleLegalMenuClose}
              sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {page.name.replace(" Legal","")}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  )}


    </Box>

{/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, margin: "0px 20px" }}>
      {homePage && (
        <Button
          key={homePage.name}
          component={Link}
          to={homePage.link}
          sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
                textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem' }}
        >
          {homePage.name}
        </Button>
      )}
      {ipoPages.length > 0 && (
        <>
          <Button
            onClick={handleMenuToggle}
            // endIcon={anchorEl ? <ExpandLessIcon  /> : <ExpandMoreIcon />}
            sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
                  textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem', display: 'flex', alignItems: 'center' }}
          >
            IPO {anchorEl ? <IoIosArrowUp fontSize="small" style={{display:"flex",alignItems:"center",marginLeft:"2px"}}/> : <IoIosArrowDown fontSize="small" style={{display:"flex",alignItems:"center",marginLeft:"2px"}}/>}
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          //  sx={{zIndex:2000}}
          >
            <Box sx={{ px: 1, py: 1 }}>
              {ipoPages.map((page) => (
                <MenuItem
                  key={page.name}
                  component={Link}
                  to={page.link}
                  onClick={handleMenuClose}
                  sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {page.name}
                </MenuItem>
              ))}
            </Box>
          </Popover>
        </>
      )}
      {remainingPages.map((page) => (
        <Button
          key={page.name}
          component={Link}
          to={page.link}
          sx={{ my: 2, color: 'white', display: "block", whiteSpace: 'nowrap', textAlign: "center",
                textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.775rem' }}
        >
          {page.name}
        </Button>
      ))}
    </Box> */}

          <Box
         ref={searchBoxRefDesktop}
            sx={{
              position: 'relative',
              display: { xs: 'flex', lg: 'flex', md:"flex", sm:"flex" },
              alignItems: 'center',
              width: '100%',
              maxWidth: '400px',
              // marginRight: '5px',
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <input
              type="text"
              style={{
                width: '100%',
                maxWidth: '400px',
                padding: '0.5rem',
                paddingLeft: '1rem',
                fontSize: '0.875rem',
                color: '#1a202c',
                border: '1px solid #cbd5e0',
                outline: 'none',
                borderRadius: '0.375rem',
                backgroundColor: '#edf2f7',
              }}
              placeholder="Search...."
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              required
            />
            {searchQuery.trim() !== '' && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(100% + 5px)',
                  fontSize: '13px',
                  width: '100%',
                  maxWidth: '400px',
                  backgroundColor: '#edf2f4',
                  border: '1px solid #d1d5db',
                  borderRadius: '0.375rem',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                  overflowY: 'auto',
                  maxHeight: '150px',
                  marginTop: '-3px',
                  listStyleType: 'none',
                  zIndex: '30',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#bfc3c9 #f3f4f6',
                }}
              >
                {suggestions.length > 0 ? (
                  suggestions.map((suggestion, index) => (
                    // <div key={suggestion}>
                      <li
                      key={suggestion}
                        style={{
                          outline: 'none',
                          textDecoration: 'none',
                          color: selectedIndex === index ? '#ff7847' : 'black',
                          padding: '0.2rem 1rem',
                          cursor: 'pointer',
                          marginBottom: '1px',
                          backgroundColor: selectedIndex === index ? '#edf2f7' : 'white',
                          transition: 'background-color 0.3s',
                        }}
                        // onClick={(e) => handleSuggestionClick(e, suggestion)}
                        onClick={() => handleSuggestionClick(suggestion)}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = '#edf2f7';
                          e.target.style.color = '#ff7847';
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = 'white';
                          e.target.style.color = 'black';
                        }}
                      >
                        {suggestion}
                      </li>
                    // </div>
                  ))
                ) : (
                  <Link to={`/`} style={{ textDecoration: 'none' }}>
                    <li
                      style={{
                        outline: 'none',
                        textDecoration: 'none',
                        color: selectedIndex ? '#ff7847' : 'black',
                        padding: '0.2rem 1rem',
                        cursor: 'pointer',
                        marginBottom: '1px',
                        backgroundColor: selectedIndex ? '#edf2f7' : 'white',
                        transition: 'background-color 0.3s',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#edf2f7';
                        e.target.style.color = '#ff7847';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = 'white';
                        e.target.style.color = 'black';
                      }}
                    >
                      IPO not available..
                    </li>
                  </Link>
                )}
              </Box>
            )}
            <button
              className="search-active"
              onClick={handleClick}
              style={{
                position: 'absolute',
                right: '0',
                fontSize: '18px',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                marginRight: '8px',
                outline: 'none',
                color: isHovered ? '#ff7847' : '#666',
                transition: 'color 0.3s',
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </Box>
        </Toolbar>
        </Container>
        
         {/* Sub Toolbar (Sub Navbar) */}
    {/* <Toolbar disableGutters sx={{ backgroundColor: '#333', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
    
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button component={Link} to="/sublink1" sx={{ color: 'white', mx: 2 }}>Sub Link 1</Button>
        <Button component={Link} to="/sublink2" sx={{ color: 'white', mx: 2 }}>Sub Link 2</Button>
       
      </Box>
    </Toolbar> */}

     
    </AppBar>
  );
}
export default Navbar;


// import * as React from 'react';
// import { Link } from 'react-router-dom';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import AdbIcon from '@mui/icons-material/Adb';
// import SearchIcon from '@mui/icons-material/Search';
// import { styled, alpha } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';

// const pages = [
//   { name: 'Home', link: '/' },
//   { name: 'Current IPO', link: '/current-ipo' },
//   { name: 'Upcoming IPO', link: '/upcoming-ipo' },
//   { name: 'Recently Listed IPO', link: '/recently-listed-ipo' },
//   { name: 'Closed IPO', link: '/closed-ipo' },
//   { name: 'IPO News', link: '/ipo-news' },
//   { name: 'IPO Calculator', link: '/ipo-calculator' }
// ];

// function Navbar() {
//   const [anchorElNav, setAnchorElNav] = React.useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     '&:hover': {
//       backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       marginLeft: theme.spacing(1),
//       width: 'auto',
//     },
//   }));
  
//   const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   }));
  
//   const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     width: '100%',
//     '& .MuiInputBase-input': {
//       padding: theme.spacing(1, 1, 1, 0),
//       // vertical padding + font size from searchIcon
//       paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//       transition: theme.transitions.create('width'),
//       [theme.breakpoints.up('sm')]: {
//         width: '20ch',
//         '&:focus': {
//           width: '40ch',
//         },
//       },
//     },
//   }));
//   return (
//     <AppBar position="static">
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
//           <Typography
//             variant="h6"
//             noWrap
//             component={Link}
//             to="/"
//             sx={{
//               mr: 2,
//               display: { xs: 'none', md: 'flex' },
//               fontFamily: 'monospace',
//               fontWeight: 700,
//               letterSpacing: '.3rem',
//               color: 'inherit',
//               textDecoration: 'none',
//             }}
//           >
//             LOGO
//           </Typography>

//           <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'left',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: 'block', md: 'none' },
//               }}
//             >
//               {pages.map((page) => (
//                 <MenuItem key={page.name} onClick={handleCloseNavMenu} component={Link} to={page.link}>
//                   <Typography textAlign="center">{page.name}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
          
//           <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
//             {pages.map((page) => (
//               <Button
//                 key={page.name}
//                 component={Link}
//                 to={page.link}
//                 sx={{ my: 2, color: 'white', display: 'block' }}
//               >
//                 {page.name}
//               </Button>
//             ))}
//           </Box>

//           <Box sx={{ flexGrow: 0 }}>
//             <Toolbar>
//               <Search>
//                 <SearchIconWrapper>
//                   <SearchIcon />
//                 </SearchIconWrapper>
//                 <StyledInputBase
//                   placeholder="Search…"
//                   inputProps={{ 'aria-label': 'search' }}
//                 />
//               </Search>
//             </Toolbar>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// }
// export default Navbar;

